import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { callAPI } from 'helpers';
import _ from 'lodash';

const i_down = (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" className="i-toggle">
    <path
      stroke="#141B34"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="16"
      strokeWidth="2"
      d="m5 7.5 5 5 5-5"
    ></path>
  </svg>
);

const i_del = (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
    <path
      fill="#D5D5D7"
      d="M10 1.667a2.926 2.926 0 0 0-2.883 2.5H2.708a.625.625 0 1 0 0 1.25h.89l1.05 10.845a2.284 2.284 0 0 0 2.282 2.071h6.14c1.186 0 2.167-.89 2.282-2.07l1.05-10.846h.89a.625.625 0 1 0 0-1.25h-4.409A2.926 2.926 0 0 0 10 1.667m0 1.25c.783 0 1.425.528 1.609 1.25H8.39A1.65 1.65 0 0 1 10 2.917M8.125 7.5c.345 0 .625.28.625.625v6.25a.625.625 0 1 1-1.25 0v-6.25c0-.345.28-.625.625-.625m3.75 0c.345 0 .625.28.625.625v6.25a.625.625 0 1 1-1.25 0v-6.25c0-.345.28-.625.625-.625"
    ></path>
  </svg>
);

const i_add = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
    style={{ marginBottom: '2px' }}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M10 6.667v6.666M13.333 10H6.667"
    ></path>
    <path
      stroke="currentColor"
      strokeWidth="1.5"
      d="M18.333 10a8.333 8.333 0 1 0-16.666 0 8.333 8.333 0 0 0 16.666 0Z"
    ></path>
  </svg>
);

const SectionForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [active01, setActive01] = useState(true);
  const [active02, setActive02] = useState(false);
  const [active03, setActive03] = useState(false);
  const [active04, setActive04] = useState(false);

  return (
    <div className="t-form">
      <h1>โต้แย้งเฉลยคำตอบ</h1>
      <div className="t-box -recheck">
        <p className="mt-0 mb-0">
          หากพบข้อผิดพลาดในการเฉลยคำตอบ ผู้เข้าสอบ
          สามารถดำเนินการโต้แย้งเฉลยคำตอบได้โดยกรอกฟอร์มสร้างคำโต้แย้งใหม่ด้านล่างได้สูงสุด 10 ข้อต่อคน{' '}
          <strong>ส่งได้ครั้งเดียว</strong>{' '}
          <strong className="color-orange">ภายในวันที่ 28 มีนาคม 2568 เวลา 23.59 น.</strong>{' '}
          หากไม่โต้แย้งในเวลาที่กำหนดถือว่ายอมรับคำตอบโดยปริยาย
        </p>
      </div>

      <div className="t-box -recheck pt-0">
        <h4 className="mt-3 mb-2">กรอกฟอร์มสร้างคำโต้แย้งใหม่ โดยเลือกวิชาและข้อที่ต้องการโต้แย้ง</h4>
        <hr className="mt-0" />
        {/* 01 */}
        <div className="t-row">
          <div className="col -full required">
            <div className="flex justiy-between ">
              <label>1. วิชา</label>
              <button onClick={() => alert('ยืนยันการลบวิชา')} className="btn-blank mt-1">
                {i_del}
              </button>
            </div>
            <select>
              <option value="">เลือกวิชา</option>
              <option value="">Thai ภาษาไทย</option>
              <option value="">Soc สังคมศึกษา</option>
              <option value="">Phy ฟิสิกส</option>
            </select>
          </div>
          <div className="col required">
            <label>ชุดแบบทดสอบ</label>
            <div className="radio-group">
              <label className="s-flex gap-2">
                <input type="radio" name="set" /> ชุดที่ 1
              </label>
              <label className="s-flex gap-2">
                <input type="radio" name="set" /> ชุดที่ 2
              </label>
            </div>
          </div>
          <div className="col required">
            <label>ข้อที่ต้องการโต้แย้ง</label>
            <select>
              <option value="">เลือกข้อ</option>
              <option value="">1</option>
              <option value="">2</option>
              <option value="">3</option>
              <option value="">4</option>
            </select>
          </div>
          <div className="col -full required">
            <label>ตัวเลือกที่ถูกต้อง</label>
            <div className="flex flex-wrap gap-4 gap-y-2">
              <label className="s-flex gap-2 no-x w-auto">
                <input type="checkbox" /> 1
              </label>
              <label className="s-flex gap-2 no-x w-auto">
                <input type="checkbox" /> 2
              </label>
              <label className="s-flex gap-2 no-x w-auto">
                <input type="checkbox" /> 3
              </label>
              <label className="s-flex gap-2 no-x w-auto">
                <input type="checkbox" /> 4
              </label>
              <label className="s-flex gap-2 no-x w-auto">
                <input type="checkbox" /> 5
              </label>
              <label className="s-flex gap-2 no-x w-auto">
                <input type="checkbox" /> ไม่มีตัวเลือกที่ถูกต้อง
              </label>
            </div>
          </div>
          <div className="col -full">
            <label>เหตุผลเพิ่มเติม</label>
            <textarea />
          </div>
          <div className="col">
            <label className="flex flex-wrap w-auto items-center">
              <span className="mb-1">แนบภาพประกอบ &nbsp;</span>
              <small className="mb-1">(JPEG หรือ PNG ขนาดไม่เกิน 500KB)</small>
            </label>
            <input type="file" accept="image/*" />
          </div>
        </div>
        <hr />
        {/* 02 */}
        <div className="t-row">
          <div className="col -full required">
            <div className="flex justiy-between ">
              <label>2. วิชา</label>
              <button onClick={() => alert('ยืนยันการลบวิชา')} className="btn-blank mt-1">
                {i_del}
              </button>
            </div>
            <select>
              <option value="">เลือกวิชา</option>
              <option value="">Thai ภาษาไทย</option>
              <option value="">Soc สังคมศึกษา</option>
              <option value="">Phy ฟิสิกส</option>
            </select>
          </div>
          <div className="col required">
            <label>ชุดแบบทดสอบ</label>
            <div className="radio-group">
              <label className="s-flex gap-2">
                <input type="radio" name="set" /> ชุดที่ 1
              </label>
              <label className="s-flex gap-2">
                <input type="radio" name="set" /> ชุดที่ 2
              </label>
            </div>
          </div>
          <div className="col required">
            <label>ข้อที่ต้องการโต้แย้ง</label>
            <select>
              <option value="">เลือกข้อ</option>
              <option value="">1</option>
              <option value="">2</option>
              <option value="">3</option>
              <option value="">4</option>
            </select>
          </div>
          <div className="col -full required">
            <label>ตัวเลือกที่ถูกต้อง</label>
            <div className="flex flex-wrap gap-4 gap-y-2">
              <label className="s-flex gap-2 no-x w-auto">
                <input type="checkbox" /> 1
              </label>
              <label className="s-flex gap-2 no-x w-auto">
                <input type="checkbox" /> 2
              </label>
              <label className="s-flex gap-2 no-x w-auto">
                <input type="checkbox" /> 3
              </label>
              <label className="s-flex gap-2 no-x w-auto">
                <input type="checkbox" /> 4
              </label>
              <label className="s-flex gap-2 no-x w-auto">
                <input type="checkbox" /> 5
              </label>
              <label className="s-flex gap-2 no-x w-auto">
                <input type="checkbox" /> ไม่มีตัวเลือกที่ถูกต้อง
              </label>
            </div>
          </div>
          <div className="col -full">
            <label>เหตุผลเพิ่มเติม</label>
            <textarea />
          </div>
          <div className="col">
            <label className="flex flex-wrap w-auto items-center">
              <span className="mb-1">แนบภาพประกอบ &nbsp;</span>
              <small className="mb-1">(JPEG หรือ PNG ขนาดไม่เกิน 500KB)</small>
            </label>
            <input type="file" accept="image/*" />
          </div>
        </div>
        <hr />
        <button className="btn-ghost flex gap-2">{i_add} เพิ่มข้อโต้แย้ง</button>
        <hr />

        {/* --- กรณีเลือกครบ 10 ข้อ เพิ่มอีกไม่ได้ --- */}
        <p className="mt-0">
          <small>กรณีเลือกครบ 10 ข้อ เพิ่มอีกไม่ได้ ให้แสดงเป็นปุ่มเทา</small>
        </p>
        <button className="btn-ghost disabled flex gap-2" onClick={() => alert('โต้แย้งเฉลยคำตอบครบ 10 ข้อแล้ว')}>
          {i_add} เพิ่มข้อโต้แย้ง
        </button>
        {/* กรณีเลือกครบ 10 ข้อ เพิ่มอีกไม่ได้ */}
        <hr className="border-orange" />
        <p className="color-orange mt-0 _heading">
          <strong>สามารถโต้แย้งเฉลยคำตอบได้เพียงครั้งเดียว และไม่สามารถขอแก้ไขภายหลังได้</strong>
        </p>
        <button className="btn-main -danger">ยืนยันโต้แย้งเฉลยคำตอบ</button>
      </div>
    </div>
  );
};

export default SectionForm;
