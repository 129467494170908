/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

// Hooks
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import EmailVerification from 'components/otp/EmailVerification';
import MobileVerification from 'components/otp/MobileVerification';
import { callAPI } from 'helpers';

const roundMap = {
  '1_2568': '1 Portfolio',
  '2_2568': '2 Quota',
  '3_2568': '3 Admission',
  '41_2568': '4 Direct Admission',
  '42_2568': '4 Direct Admission'
}

const OfferAccept = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language.toLowerCase();
  const offerId = useParams().offerId;
  const roundType = useParams().roundType;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile = useSelector((state) => _.get(state, 'app.user') || {});

  const [loading, setLoading] = useState(true);
  const [offers, setOffers] = useState([]);
  const [replies, setReplies] = useState([]);
  useEffect(() => {
    const fetchEnrollmentOffers = async () => {
      try {
        setLoading(true);
        const { offers, replies } = await callAPI({
          url: '/applicants/me/enrollment-offers',
          query: { type: roundType },
        });
        setOffers(offers);
        setReplies(replies);
        setLoading(false);

        const lastReply = _.last(replies);
        if (_.get(lastReply, `enrollment_offer._id`) === offerId || replies.length >= 3) {
          navigate('/profile');
        }
      } catch (error) {
        console.error(`Error while trying to fetch enrollment offers: ${error.message}`);
        setLoading(false);
      }
    };

    fetchEnrollmentOffers();
  }, [navigate, offerId, roundType]);

  const [verifyMethod, setVerifyMethod] = useState();
  const [otpVisible, setOtpVisible] = useState(false);

  const quotaUsed = replies.length;
  const currentOffer = _.find(offers, (offer) => offer._id === offerId);

  const onEmailOtpSuccess = useCallback(() => {
    dispatch({ type: 'UPDATE_USER', result: { ...profile, email_is_verified: 1 } });
    navigate(-1);
  }, [dispatch, profile, navigate]);

  const onSmsOtpSuccess = useCallback(() => {
    dispatch({ type: 'UPDATE_USER', result: { ...profile, telephone_is_verified: 1 } });
    navigate(-1);
  }, [dispatch, profile, navigate]);

  if (loading) return null;
  const majorName = _.get(currentOffer, `program.major_name_${lang}`);
  const projectName = _.get(currentOffer, `program_project.project_name_${lang}`);
  return (
    <main className="site-body">
      <div className="t-form">
        <h1>{t('รอบ')} {roundMap[roundType]}</h1>
        {!otpVisible && (
          <>
            <h2 className="sub">{t('กรุณาเลือกช่องทางในการรับรหัส OTP ยืนยันสิทธิ์')}</h2>
            <div className="r-program">
              <div
                className="u-logo"
                style={{ backgroundImage: `url('/assets/img/logo/${_.get(currentOffer, `program.university_id`)}.png')` }}
              />
              <ul>
                <li>
                  <i className="i-u"></i>
                  <b>{_.get(currentOffer, `program.university_name_${lang}`)}</b>
                </li>
                <li>
                  <i className="i-pin"></i>
                  <b>{_.get(currentOffer, `program.campus_name_${lang}`)}</b>
                </li>
                <li>
                  <i>•</i>
                  <span>{_.get(currentOffer, `program.faculty_name_${lang}`)}</span>
                </li>
                <li>
                  <i>•</i>
                  <span>{_.get(currentOffer, `program.field_name_${lang}`)}</span>
                </li>
                {majorName && majorName !== '0' ? (
                  <li>
                    <i>•</i>
                    <span>{majorName}</span>
                  </li>
                ) : null}
                {majorName && majorName !== '0' ? (
                  <li>
                    <i>•</i>
                    <span>
                      {t('วิชาเอก')}
                      {majorName}
                    </span>
                  </li>
                ) : null}
                <li>
                  <i>•</i>
                  <span>{_.get(currentOffer, `program.program_name_${lang}`)}</span>
                </li>
                {Boolean(_.get(currentOffer, `program.program_type_name_th`)) && (
                  <li>
                    <i>•</i>
                    <span>{_.get(currentOffer, `program.program_type_name_th`)}</span>
                  </li>
                )}
                {projectName && projectName !== '0' ? (
                  <li>
                    <i>•</i>
                    <span>
                      {t('โครงการ ')}
                      {projectName}
                    </span>
                  </li>
                ) : null}
              </ul>
            </div>
            <div className="i-hearts">
              <i className={cn('i-heart', { '-blink': quotaUsed === 2, '-used': quotaUsed > 2 })} />
              <i className={cn('i-heart', { '-blink': quotaUsed === 1, '-used': quotaUsed > 1 })} />
              <i className={cn('i-heart', { '-blink': quotaUsed === 0, '-used': quotaUsed > 0 })} />
              <small>
                {t('ตัดสินใจครั้งที่')} {1 + quotaUsed}/3
              </small>
            </div>
          </>
        )}

        {!otpVisible ? (
          <MethodSelector
            verifyMethod={verifyMethod}
            setVerifyMethod={setVerifyMethod}
            onClick={() => setOtpVisible(true)}
          />
        ) : verifyMethod === 'email' ? (
          <EmailVerification
            studentType="offer-reply"
            offerId={offerId}
            roundType={roundType}
            email={profile.email}
            onSuccess={onEmailOtpSuccess}>
            <div className="i-hearts">
              <i className={cn('i-heart', { '-blink': quotaUsed === 2, '-used': quotaUsed > 2 })} />
              <i className={cn('i-heart', { '-blink': quotaUsed === 1, '-used': quotaUsed > 1 })} />
              <i className={cn('i-heart', { '-blink': quotaUsed === 0, '-used': quotaUsed > 0 })} />
              <small>
                {t('ตัดสินใจครั้งที่')} {1 + quotaUsed}/3
              </small>
            </div>
          </EmailVerification>
        ) : verifyMethod === 'mobile' ? (
          <MobileVerification
            studentType="offer-reply"
            offerId={offerId}
            roundType={roundType}
            mobile={profile.telephone}
            onSuccess={onSmsOtpSuccess}>
            <div className="i-hearts">
              <i className={cn('i-heart', { '-blink': quotaUsed === 2, '-used': quotaUsed > 2 })} />
              <i className={cn('i-heart', { '-blink': quotaUsed === 1, '-used': quotaUsed > 1 })} />
              <i className={cn('i-heart', { '-blink': quotaUsed === 0, '-used': quotaUsed > 0 })} />
              <small>
                {t('ตัดสินใจครั้งที่')} {1 + quotaUsed}/3
              </small>
            </div>
          </MobileVerification>
        ) : null}
      </div>
    </main>
  );
};

export default OfferAccept;

const MethodSelector = ({ onClick, verifyMethod, setVerifyMethod }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const profile = useSelector((state) => _.get(state, 'app.user') || {});
  const formattedMobile = [
    profile.telephone.slice(0, 3),
    profile.telephone.slice(3, 6),
    profile.telephone.slice(-4),
  ].join(' ');

  return (
    <>
      <ul className="r-radio">
        <li>
          <label>
            <input
              type="radio"
              name="confirm"
              checked={verifyMethod === 'mobile'}
              onClick={() => setVerifyMethod('mobile')}
            />
            <strong>{t('ยืนยันสิทธิ์ผ่านเบอร์มือถือ')}</strong>
            <small>
              {t('หมายเลข')} {formattedMobile}
            </small>
          </label>
        </li>
        <li>
          <label>
            <input
              type="radio"
              name="confirm"
              checked={verifyMethod === 'email'}
              onClick={() => setVerifyMethod('email')}
            />
            <strong>{t('ยืนยันสิทธิ์ผ่านอีเมล')}</strong>
            <small>{profile.email}</small>
          </label>
        </li>
      </ul>

      <div className="action">
        <a
          className={cn('btn-main', { disabled: !verifyMethod, 'cursor-pointer': Boolean(verifyMethod) })}
          onClick={!verifyMethod ? () => { } : onClick}
        >
          {t('ส่ง OTP เพื่อยืนยันสิทธิ์')}
        </a>
        <h4><a onClick={() => navigate(-1)} className="cursor-pointer color-gray">{t('ยกเลิก')}</a></h4>
      </div>
      <p className="text-center _small">{t('หากมีการเปลี่ยนแปลงเบอร์มือถือหรืออีเมล')}
        {t('สามารถแก้ไขได้ที่หน้า')} <Link to='/profile/applicant-info' className="color-black"><u>ข้อมูลการลงทะเบียน</u></Link></p>
    </>
  );
};
