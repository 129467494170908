/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { callAPI } from 'helpers';
import _ from 'lodash';

const ExamDisputeConsent = ({ type = 'tgattpat' }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profile = useSelector((state) => _.get(state, 'app.user') || {});

  useEffect(() => {
    const consentAccepted = _.get(profile, `consents_accepted_at.${type}_exam_recheck_2568`);
    if (consentAccepted) {
      navigate(`/profile/exam-recheck/${type}`);
    }
  }, [navigate, profile, type]);

  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);

  const allChecked = ![checked1, checked2, checked3, checked4, checked5].some((checked) => !checked);

  const [accepting, setAccepting] = useState(false);
  const _onAcceptConsent = async () => {
    if (!allChecked || accepting) return;
    try {
      setAccepting(true);
      await callAPI({
        method: 'POST',
        url: '/applicants/me/consents',
        body: {
          consentType: `${type}_exam_dispute_2568`,
        },
      });
      setAccepting(false);
      dispatch({
        type: 'UPDATE_USER',
        result: {
          ...profile,
          consents_accepted_at: {
            ...profile.consents_accepted_at,
            [`${type}_exam_dispute_2568`]: true
          }
        }
      });
      navigate(`/profile/exam-dispute/${type}`);
    } catch (error) {
      console.error(`Error while trying to accept consent: ${error.message}`);
      setAccepting(false);
    }
  };

  return (
    <main className="site-body">
      <div className="t-form">
        <h1>โต้แย้งเฉลยคำตอบ</h1>
        <div className="t-box -recheck">
          <h2 className="s-flex -center color-orange">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13 14C11 20 4 14 2 20"
                stroke="#FF6600"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16 16H20.0022C21.1067 16 22.0022 15.1046 22.0022 14V6C22.0022 4.89543 21.1067 4 20.0022 4H10.0013C8.89775 4 8.00274 4.89384 8.00131 5.9974L8 7"
                stroke="#FF6600"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 14C9.10457 14 10 13.1046 10 12C10 10.8954 9.10457 10 8 10C6.89543 10 6 10.8954 6 12C6 13.1046 6.89543 14 8 14Z"
                stroke="#FF6600"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 8H18M18 12H15.5"
                stroke="#FF6600"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            {t('โต้แย้งเฉลยคำตอบ')}
          </h2>
          <p>
            การโต้แย้งเฉลยคำตอบ เป็นการส่งความคิดเห็นเกี่ยวกับเฉลยคำตอบที่ประกาศโดย ทปอ. ที่ผู้เข้าสอบอาจไม่เห็นด้วย
            รายการโต้แย้งทั้งหมดจะถูกสรุปและนำส่งให้แก่คณะทำงานชุดเฉลยแบบทดสอบและพิจารณาข้อโต้แย้งที่แต่งตั้งโดยคณะกรรมการดำเนินงาน
            TCAS ทั้งนี้คำตัดสินของคณะทำงานฯ ถือเป็นที่สิ้นสุด
          </p>
          <p>
            {t('กรุณาอ่านและ')} <b>{t('กดยอมรับ')}</b> {t('ข้อกำหนดและเงื่อนไขทุกข้อ ก่อนกด')} <b>{t('รับทราบ')}</b>
          </p>
          <ul className="r-consent">
            <li>
              <label>
                <input type="checkbox" checked={checked1} onChange={(e) => setChecked1(e.target.checked)} />
                <span>
                  1.{' '}
                  {t(
                    'ผู้เข้าสอบสามารถโต้แย้งเฉลยคำตอบได้ไม่เกิน 10 ข้อต่อคน โดยรายวิชาที่โต้แย้ง ต้องเป็นวิชาที่ผู้เข้าสอบได้เข้าสอบจริง'
                  )}
                </span>
              </label>
            </li>
            <li>
              <label>
                <input type="checkbox" checked={checked2} onChange={(e) => setChecked2(e.target.checked)} />
                <span>
                  2.{' '}
                  {t(
                    'การโต้แย้งเฉลยคำตอบ ขอให้ผู้สมัครดำเนินการตามหลักเหตุผลและข้อเท็จจริง เพื่อให้การพิจารณาเป็นไปได้อย่างรวดเร็ว เขียนเหตุผลที่ต้องการโต้แย้งให้สุภาพ สั้น และกระชับ สามารถแนบภาพประกอบคำอธิบายได้ในรูปแบบ JPEG หรือ PNG ขนาดไม่เกิน 500KB สูงสุดข้อละ 1 รูป'
                  )}
                </span>
              </label>
            </li>
            <li>
              <label>
                <input type="checkbox" checked={checked3} onChange={(e) => setChecked3(e.target.checked)} />
                <span>
                  3.{' '}
                  ก่อนดำเนินการส่งข้อโต้แย้ง ผู้สมัครต้องอ่านสรุปข้อโต้แย้งก่อนกดส่งอย่างรอบคอบ เนื่องจาก<u>จะไม่สามารถย้อนกลับมาแก้ไขใหม่ได้</u>
                </span>
              </label>
            </li>
            <li>
              <label>
                <input type="checkbox" checked={checked4} onChange={(e) => setChecked4(e.target.checked)} />
                <span>
                  4.{' '}
                  {t(
                    'การตัดสินข้อโต้แย้งพิจารณาจากเหตุผลประกอบการสนับสนุนข้อโต้แย้ง ข้อเท็จจริง หลักการ รวมถึงแนวความคิดของทีมผู้ออกแบบทดสอบต้องการจะวัดเป็นหลัก โดยมิได้นำจำนวนผู้โต้แย้งในแต่ละข้อมาใช้ในการพิจารณา'
                  )}
                </span>
              </label>
            </li>
            <li>
              <label>
                <input type="checkbox" checked={checked5} onChange={(e) => setChecked5(e.target.checked)} />
                <span>
                  5.{' '}
                  การโต้แย้งสามารถดำเนินการได้<u>เพียงครั้งเดียว</u> และ<u>ไม่สามารถย้อนกลับ</u>มาแก้ไขใหม่ได้ โดยจะต้องดำเนินการยืนยันการยื่นข้อโต้แย้งโดยการกรอกรหัส OTP ที่ได้รับ
                  <strong className="color-orange">ภายในวันที่ 28 มีนาคม 2568 เวลา 23.59 น.</strong>
                </span>
              </label>
            </li>

            <li>
              <label>
                <input type="checkbox" disabled checked={allChecked} />
                <b>
                  {t('ข้าพเจ้าได้อ่านและยอมรับข้อกำหนดและเงื่อนไขดังกล่าวสำหรับกรณี')}{' '}
                  <span className="color-orange">{t('การโต้แย้งเฉลยคำตอบ')}</span>
                </b>
              </label>
            </li>
          </ul>
          <div className="content-bottom">
            <a className="btn-cancel">
              <span className="icon">×</span> {t('ยกเลิก')}
            </a>
            <a
              className={cn({
                'btn-main': true,
                '-danger': true,
                'cursor-pointer': allChecked,
                disabled: !allChecked,
              })}
              onClick={_onAcceptConsent}>
              {t('รับทราบ')}
            </a>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ExamDisputeConsent;
