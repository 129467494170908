/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';

// Hooks
import { useTranslation } from 'react-i18next';

// Components
import SectionApplicantInfo from 'components/profile/apply-exams/SectionApplicantInfo';
// import SectionApplyTGATTPAT from 'components/profile/apply-exams/SectionApplyTGATTPAT';
import SectionTgatTpatSummary from 'components/profile/exam-applications/SectionTgatTpatSummary';
// import SectionApplyALevel from 'components/profile/apply-exams/SectionApplyALevel';
import SectinoALevelSummary from 'components/profile/exam-applications/SectionALevelSummary';
import Round1 from 'components/profile/enrollments/round1/Round1';
import Round2 from 'components/profile/enrollments/round2/Round2';
import Round3 from 'components/profile/enrollments/round3/Round3';
// import Round3Announce from 'components/profile/enrollments/round3/Round3Announce';
import Round4 from 'components/profile/enrollments/round4/Round4';

const Dashboard = () => {
  const { t } = useTranslation();

  return (
    <>
      <main className="site-body">
        <div className="t-form">
          <h1>การสมัคร</h1>
          <SectionApplicantInfo />
          {/* <SectionApplyTGATTPAT /> */}
          <SectionTgatTpatSummary />
          {/* <SectionApplyALevel /> */}
          <SectinoALevelSummary />
          <div className='r-all'>
            <h2>{t('รอบการสมัคร')}</h2>
            <Round1 />
            <Round2 />
            <Round3 />
            {/* <Round3Announce /> */}
            <Round4 />
          </div>
        </div>
      </main>
      <footer className="site-footer">
        <a href="http://www.cupt.net/" target="_blank" rel="noopener noreferrer">
          <img src="/assets/img/logo/cupt.png" alt="CUPT Logo" className="logo" width="228" height="44" />
        </a>
        <div className="address">
          <b>{t('สมาคมที่ประชุมอธิการบดีแห่งประเทศไทย')}</b>
          {t('328 อาคารสำนักงานคณะกรรมการการอุดมศึกษา แขวงทุ่งพญาไท เขตราชเทวี กรุงเทพฯ')} <br />
          {t('โทรศัพท์ 02-126-5111 วันทำการ จันทร์-ศุกร์ เวลา 9.00-17.30 น.')} &nbsp;
          <Link to="/privacy">{t('เงื่อนไขการใช้งานและนโยบายความเป็นส่วนตัว')}</Link>
        </div>
        <div className="social">
          <a href="https://www.facebook.com/CUPTmytcas/" target="_blank" rel="noopener noreferrer">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
              <title>facebook</title>
              <path
                fill="#ffffff"
                d="M16 0.625c4.25 0 7.875 1.5 10.875 4.5s4.5 6.625 4.5 10.875c0 4.25-1.5 7.813-4.5 10.875-3 3-6.625 4.5-10.875 4.5s-7.875-1.5-10.875-4.5c-3-3.063-4.5-6.625-4.5-10.875s1.5-7.875 4.5-10.875c3-3 6.625-4.5 10.875-4.5zM19.625 11.188c0 0 0-2.5 0-2.5s-2.25 0-2.25 0c-1 0-1.75 0.375-2.375 1.063-0.688 0.688-1 1.563-1 2.625 0 0 0 1.375 0 1.375s-2.438 0-2.438 0c0 0 0 2.375 0 2.375s2.438 0 2.438 0c0 0 0 7.125 0 7.125s2.75 0 2.75 0c0 0 0-7.125 0-7.125s2.875 0 2.875 0c0 0 0-2.375 0-2.375s-2.875 0-2.875 0c0 0 0-1.625 0-1.625 0-0.625 0.188-0.938 0.625-0.938 0 0 2.25 0 2.25 0z"
              ></path>
            </svg>
          </a>
          <a href="https://twitter.com/MytcasC" target="_blank" rel="noopener noreferrer">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
              <title>twitter</title>
              <path
                fill="#ffffff"
                d="M16 0.625c4.25 0 7.875 1.5 10.875 4.5s4.5 6.625 4.5 10.875c0 4.25-1.5 7.813-4.5 10.875-3 3-6.625 4.5-10.875 4.5s-7.813-1.5-10.875-4.5c-3-3.063-4.5-6.625-4.5-10.875s1.5-7.875 4.5-10.875c3.063-3 6.625-4.5 10.875-4.5zM22.25 13.188c0.688-0.5 1.125-1 1.5-1.563-0.688 0.25-1.25 0.438-1.75 0.5 0.625-0.438 1.125-0.938 1.375-1.688-0.625 0.313-1.313 0.563-1.938 0.688-0.625-0.563-1.313-0.875-2.25-0.875-0.813 0-1.563 0.313-2.125 0.875-0.563 0.625-0.875 1.313-0.875 2.125 0 0.063 0 0.125 0.063 0.313 0 0.188 0 0.313 0 0.375-2.563-0.125-4.625-1.188-6.188-3.188-0.313 0.5-0.438 1-0.438 1.563 0 1.063 0.438 1.875 1.375 2.5-0.563 0-1-0.188-1.375-0.438 0 0 0 0.063 0 0.063 0 0.75 0.188 1.375 0.625 1.938 0.5 0.563 1.063 0.875 1.813 1.063-0.375 0.063-0.625 0.125-0.813 0.125-0.25 0-0.438-0.063-0.563-0.063 0.438 1.375 1.375 2.063 2.813 2.063-1.063 0.875-2.313 1.313-3.75 1.313 0 0-0.75 0-0.75 0 1.5 0.875 3 1.313 4.625 1.313 2.625 0 4.688-0.875 6.25-2.625 1.625-1.75 2.375-3.75 2.375-6 0 0 0-0.375 0-0.375z"
              ></path>
            </svg>
          </a>
        </div>
      </footer>
    </>
  );
};

export default Dashboard;
