/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useCallback } from 'react';
import _ from 'lodash';
import { callAPI, compressImage } from 'helpers'
import cn from 'classnames';
import moment from 'moment';

// Components
import MethodSelector from 'components/otp/MethodSelector';
import EmailVerification from 'components/otp/EmailVerification';
import MobileVerification from 'components/otp/MobileVerification';
import ErrorBox from 'components/registrations/ErrorBox';

// Hooks
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Data
import { ALEVELINFOS } from 'data/exam-days';
import { pbt_alevel_dispute } from 'data/enabled';

const subjectMap = ALEVELINFOS.reduce((_map, { date, dayIndex, examSlots }) => {
  return _.merge(
    _map,
    _.keyBy(
      examSlots.map((slot, slotIndex) => _.merge(slot, { date, dayIndex, slotIndex })),
      'subjectKey'
    )
  );
}, {});


const ExamDispute = ({ type = 'alevel' }) => {
  const navigate = useNavigate();
  const profile = useSelector((state) => _.get(state, 'app.user') || {});

  const [loading, setLoading] = useState(true);
  const [applications, setApplications] = useState([]);
  const [summarized, setSummarized] = useState(false);

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        setLoading(true);
        const { applications, timestamp, alevel_disputed, alevel_dispute_summarized } = await callAPI({
          url: '/applicants/me/exam-applications',
          query: { type }
        });

        const isAvailable = moment(timestamp).isBetween(pbt_alevel_dispute.start, pbt_alevel_dispute.end);
        if (!applications.length || !isAvailable || alevel_disputed) {
          navigate('/profile');
        }


        setApplications(applications);
        setSummarized(alevel_dispute_summarized);
        setLoading(false);
      } catch (error) {
        console.error(`Error while trying to fetch applications: ${error.message}`);
        setLoading(false);
      }
    }

    const consentAccepted = _.get(profile, `consents_accepted_at.${type}_exam_dispute_2568`);
    if (consentAccepted) {
      fetchApplications();
    } else {
      navigate(`/profile/exam-dispute-consent/${type}`);
    }
  }, [navigate, profile, type]);

  useEffect(() => {
    const classNames = document.getElementById('root').className.split(' ');
    if (loading) {
      document.getElementById('root').className = _.uniq(classNames.concat(['is-loading'])).join(' ');
    } else {
      document.getElementById('root').className = classNames.filter((name) => name !== 'is-loading').join(' ');
    }
  }, [loading]);

  const [currentStep, setCurrentStep] = useState(1);
  const [verifyMethod, setVerifyMethod] = useState();
  const [otpVisible, setOtpVisible] = useState(false);

  const onVerifyMethodSelect = (method) => {
    setVerifyMethod(method);
    setOtpVisible(true);
  }

  const onSuccess = useCallback(() => {
    navigate('/profile');
  }, [navigate]);

  const [disputes, setDisputes] = useState([]);
  const _toStep2 = (disputes) => {
    setDisputes(disputes);
    setCurrentStep(2);
  }

  const bodyParams = { disputes };
  if (loading) return null;
  return (
    <main className="t-body">
      <div className="t-form">
        {loading && <div className="t-loading" />}
        <h1 className="text-center">โต้แย้งเฉลยคำตอบ</h1>
        {currentStep === 1 ? (
          <SectionForm
            type={type}
            applications={applications}
            summarized={summarized}
            onClick={_toStep2} />
        ) : currentStep === 2 ? (
          <>
            {!otpVisible ? (
              <>
                <div className='t-box -noti'>
                  <div className='color-orange'>
                    กรุณาเลือกช่องทางในการส่งรหัส OTP ยืนยันโต้แย้งเฉลยคำตอบ
                    &nbsp;
                    <strong className='color-orange'>เมื่อยืนยัน OTP แล้ว จะไม่สามารถยกเลิกหรือแก้ไขภายหลังได้</strong>
                  </div>
                </div>
                <div className='exam-box mx-0'>
                  <ul className='exam-box-list'>
                    <li>
                      <div className='_heading color-blue'>
                        โต้แย้งเฉลยคำตอบ <b>ทั้งหมด {disputes.length} ข้อ</b>
                        {disputes.map(({ subject_name, set, question_id }, index) => {
                          const subject = subjectMap[subject_name] || {};
                          return (
                            <div>
                              {!!index && <div style={{ borderTop: '1px dashed #888', margin: '15px 0' }} />}
                              <div style={{ display: 'inline-block', verticalAlign: 'top', marginRight: 5 }}>
                                {index + 1}.
                              </div>
                              <div style={{ display: 'inline-block', verticalAlign: 'top' }}>
                                <div>วิชา <b>{subject.subjectKey} {subject.subject_name}</b></div>
                                <div>ชุดแบบทดสอบ <b>{set}</b></div>
                                <div>ข้อที่ <b>{question_id}</b></div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </li>
                  </ul>
                </div>
                <MethodSelector onClick={onVerifyMethodSelect} />
              </>
            ) : verifyMethod === 'email' ? (
              <EmailVerification
                studentType="exam-dispute"
                email={profile.email}
                bodyParams={bodyParams}
                onSuccess={onSuccess} />
            ) : verifyMethod === 'mobile' ? (
              <MobileVerification
                studentType="exam-dispute"
                mobile={profile.telephone}
                bodyParams={bodyParams}
                onSuccess={onSuccess} />
            ) : null}
          </>
        ) : null}
      </div>
    </main>
  )

}

export default ExamDispute;


const i_down = (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" className="i-toggle">
    <path
      stroke="#141B34"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="16"
      strokeWidth="2"
      d="m5 7.5 5 5 5-5"
    ></path>
  </svg>
);

const i_del = (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
    <path
      fill="#D5D5D7"
      d="M10 1.667a2.926 2.926 0 0 0-2.883 2.5H2.708a.625.625 0 1 0 0 1.25h.89l1.05 10.845a2.284 2.284 0 0 0 2.282 2.071h6.14c1.186 0 2.167-.89 2.282-2.07l1.05-10.846h.89a.625.625 0 1 0 0-1.25h-4.409A2.926 2.926 0 0 0 10 1.667m0 1.25c.783 0 1.425.528 1.609 1.25H8.39A1.65 1.65 0 0 1 10 2.917M8.125 7.5c.345 0 .625.28.625.625v6.25a.625.625 0 1 1-1.25 0v-6.25c0-.345.28-.625.625-.625m3.75 0c.345 0 .625.28.625.625v6.25a.625.625 0 1 1-1.25 0v-6.25c0-.345.28-.625.625-.625"
    ></path>
  </svg>
);

const i_add = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
    style={{ marginBottom: '2px' }}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M10 6.667v6.666M13.333 10H6.667"
    ></path>
    <path
      stroke="currentColor"
      strokeWidth="1.5"
      d="M18.333 10a8.333 8.333 0 1 0-16.666 0 8.333 8.333 0 0 0 16.666 0Z"
    ></path>
  </svg>
);

const SectionForm = ({ type, applications, summarized, onClick }) => {
  const [activeMap, setActiveMap] = useState(applications.reduce((map, { subject_name }, index) => ({
    ...map,
    [subject_name]: false
  }), {}));

  const [disputes, setDisputes] = useState([{}]);
  const [errors, setErrors] = useState([]);

  const _onButtonClick = () => {
    const isMissing = ({ subject_name, set, question_id, answer, reason }) => {
      return !subject_name || !set || !question_id || !answer || !reason;
    }

    if (disputes.some(isMissing)) {
      setErrors(
        disputes.map(({ subject_name, set, question_id, answer, reason }) => ({
          subject_name: !subject_name ? 'กรุณาระบุชื่อวิชา' : null,
          set: !set ? 'กรุณาระบุชุดแบบทดสอบ' : null,
          question_id: !question_id ? 'กรุณาระบุข้อที่ต้องการโต้แย้ง' : null,
          answer: !answer ? 'กรุณาระบุตัวเลือกที่ถูกต้อง' : null,
          reason: !reason ? 'กรุณาระบุเหตุผลเพิ่มเติม' : null
        }))
      );
    } else {
      onClick(disputes);
    }
  }

  const _onDelete = (index) => {
    setDisputes(_disputes => _disputes.filter((_, _index) => index !== _index));
  }

  const _isChecked = ({ subject_name, set, question_id, answer }) => {
    return disputes.some(dispute => {
      return dispute.subject_name === subject_name &&
        dispute.set === set &&
        dispute.question_id === question_id &&
        dispute.answer === answer
    });
  }

  const _onDisputeChange = (key, value, index) => {
    setDisputes(_disputes =>
      _disputes.map((_dispute, _index) => {
        if (_index === index) {
          return {
            ..._dispute,
            [key]: value
          };
        }
        return _dispute;
      })
    );
  }

  return (
    <>
      {summarized ? (
        <div className="t-box -recheck">
          <p className="mt-0">หากพบข้อผิดพลาดในการเฉลยคำตอบ ผู้เข้าสอบ สามารถดำเนินการโต้แย้งเฉลยคำตอบได้โดย</p>
          <p>
            1. เลือก <strong>เห็นด้วย</strong> กับคำโต้แย้งของผู้เข้าสอบคนอื่นๆ <br />
            และ/หรือ <br />
            2. กรอกฟอร์มสร้างคำโต้แย้งใหม่ด้านล่าง
          </p>
          <p className="mb-0">
            โดยนับรวมทั้งข้อ 1 และ 2 ได้สูงสุด 10 ข้อต่อคน <strong>ส่งได้ครั้งเดียว</strong>{' '}
            <strong className="color-orange">ภายในวันที่ 28 มีนาคม 2568 เวลา 23.59 น.</strong>{' '}
            หากไม่โต้แย้งในเวลาที่กำหนดถือว่ายอมรับคำตอบโดยปริยาย
          </p>
        </div>
      ) : (
        <div className="t-box -recheck">
          <div className="mt-0">
            หากพบข้อผิดพลาดในการเฉลยคำตอบ ผู้เข้าสอบ สามารถดำเนินการโต้แย้งเฉลยคำตอบได้โดย
            กรอกฟอร์มสร้างคำโต้แย้งใหม่ด้านล่างได้สูงสุด 10 ข้อต่อคน <strong>ส่งได้ครั้งเดียว</strong>{' '}
            <strong className="color-orange">ภายในวันที่ 28 มีนาคม 2568 เวลา 23.59 น.</strong>{' '}
            หากไม่โต้แย้งในเวลาที่กำหนดถือว่ายอมรับคำตอบโดยปริยาย
          </div>
        </div>
      )}

      <div className="t-box -recheck pt-0">
        {summarized && (
          <>
            <h4 className="mt-3 mb-2">
              1. เลือก <u>เห็นด้วย</u> กับคำโต้แย้งของผู้เข้าสอบคนอื่นๆ
            </h4>
            <hr className="mt-0 mb-0" />
            <div>
              {applications
                .filter(({ set }) => set > 0)
                .map(({ subject_name, set, mdq_list }) => {
                  const subject = subjectMap[subject_name] || {};
                  return (
                    <div className={cn('t-collapse t-vetos', { active: activeMap[subject_name] })}>
                      <h3
                        className="t-toggle flex justify-between items-center"
                        onClickCapture={() => setActiveMap(_map => ({ ..._map, [subject_name]: !_map[subject_name] }))}
                      >
                        <span>
                          วิชา {subject.subjectKey} {subject.subjectName} <strong>ชุดที่ {set}</strong>
                        </span>
                        {i_down}
                      </h3>
                      <div className="t-content">
                        <table>
                          <thead>
                            <tr>
                              <th>ข้อที่</th>
                              <th>ตัวเลือกที่ถูกต้อง</th>
                              <th>เห็นด้วย?</th>
                            </tr>
                          </thead>
                          {(mdq_list || []).length ? (
                            <tbody>
                              {(mdq_list || []).map(({ question_id, answer }, index) =>
                                <tr key={index}>
                                  <td>{question_id}</td>
                                  <td>{answer === 'unanswerable' ? 'ไม่มีคำตอบที่ถูกต้อง' : answer}</td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      checked={_isChecked({ subject_name, set, question_id, answer })}
                                      onChange={() => {
                                        if (disputes.length < 10) {
                                          setDisputes(_disputes => {
                                            const matchIndex = _.findIndex(_disputes, _dispute =>
                                              _dispute.subject_name === subject_name &&
                                              _dispute.set === set &&
                                              _dispute.question_id === question_id);
                                            if (matchIndex === -1) {
                                              return _disputes
                                                .filter(_dispute => _dispute.subject_name)
                                                .concat({ subject_name, set, question_id, answer });
                                            } else {
                                              let data = _disputes.filter((_dispute, _index) => _index !== matchIndex);
                                              if (_.get(_disputes, `${matchIndex}.answer`) !== answer) {
                                                data = data.concat({ subject_name, set, question_id, answer });
                                              }

                                              if (data.length) return data;
                                              return [{ subject_name: '' }];
                                            }
                                          });
                                        }
                                      }} />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          ) : (
                            <tbody>
                              <tr>
                                <td colSpan={3} style={{ padding: '20px 0' }}>
                                  ยังไม่มีข้อมูลเพียงพอ
                                </td>
                              </tr>
                            </tbody>
                          )}
                        </table>
                      </div>
                    </div>
                  );
                })}
            </div>
            <hr className="border-orange mt-0" />
          </>
        )}
        <h4 className="mt-3 mb-2">{summarized ? '2. ' : ''}กรอกฟอร์มสร้างคำโต้แย้งใหม่ โดยเลือกวิชาและข้อที่ต้องการโต้แย้ง</h4>
        <hr className="mt-0" />
        {disputes.map((dispute, index) =>
          <DisputeForm
            key={index}
            index={index}
            applications={applications}
            subjectMap={subjectMap}
            data={dispute}
            errors={errors[index]}
            onDataChange={(key, value) => _onDisputeChange(key, value, index)}
            deletable={disputes.length > 1}
            onDelete={() => _onDelete(index)} />
        )}
        {(disputes.length < 10) && (
          <>
            <hr />
            <button
              className={cn("btn-ghost flex gap-2", { disabled: disputes.length >= 10 })}
              onClickCapture={() => setDisputes(_disputes => _disputes.concat([{}]))}>
              {i_add} เพิ่มข้อโต้แย้ง
            </button>
          </>
        )}
        {/* กรณีเลือกครบ 10 ข้อ เพิ่มอีกไม่ได้ */}
        <hr className="border-orange" />
        <p className="color-orange mt-0 _heading">
          <strong>สามารถโต้แย้งเฉลยคำตอบได้เพียงครั้งเดียว และไม่สามารถขอแก้ไขภายหลังได้</strong>
        </p>
        <button
          className={cn("btn-main -danger cursor-pointer")}
          onClick={_onButtonClick}>
          ยืนยันโต้แย้งเฉลยคำตอบ
        </button>
      </div>
    </>
  );
};

const DisputeForm = ({
  index,
  applications,
  subjectMap,
  data = {},
  errors = {},
  onDataChange,
  deletable,
  onDelete,
}) => {
  const currentApp = _.find(applications, app => app.subject_name === data.subject_name) || {};
  const currentSubject = _.get(subjectMap, data.subject_name);
  const questionCount = _.get(currentSubject, 'questionCount');
  const typeMap = _.get(currentSubject, 'questionType') || {};
  const options = applications.filter(({ set }) => set > 0).map(({ subject_name }) => {
    const subject = subjectMap[subject_name] || {};
    return (
      <option
        key={subject_name}
        value={subject_name}>
        {subject.subjectKey} {subject.subjectName}
      </option>
    )
  });

  const _onAnswerSelect = (key) => {
    const array = _.compact((data.answer || '').replace('unanswerable', '').split(','));
    if (array.includes(key)) {
      onDataChange('answer', array.filter(elem => elem !== key).join(','));
    } else {
      onDataChange('answer', (array.concat(key)).sort().join(','));
    }
  }

  const _onAttachedImageChange = async (e) => {
    const _file = await compressImage(e.target.files[0]);
    onDataChange('attached_image', _file);
  }

  const _onDelete = () => {
    if (deletable) {
      onDelete();
    }
  }

  const errorMessages = Object.keys(errors).filter(key => errors[key]).map(key => errors[key]);
  let questionType = 'single';
  if (typeMap.multiple && (data.question_id >= typeMap.multiple[0] && data.question_id <= typeMap.multiple[1])) {
    questionType = 'multiple';
  }
  if (typeMap.number && (data.question_id >= typeMap.number[0] && data.question_id <= typeMap.number[1])) {
    questionType = 'number';
  }

  return (
    <>
      {!!errorMessages.length && (
        <>
          {!!index && <hr />}
          <ErrorBox
            isFull
            hidden={!errorMessages.length}
            errors={errorMessages} />
        </>
      )}
      <div className="t-row">
        <div className="col -full required">
          <div className="flex justiy-between ">
            <label
              style={errors.subject_name ? { color: '#ea0c42' } : {}}>{index + 1}. วิชา</label>
            {deletable && (
              <button className="btn-blank mt-1" onClick={_onDelete}>
                {i_del}
              </button>
            )}
          </div>
          <select
            style={errors.subject_name ? { borderColor: '#ea0c42' } : {}}
            value={data.subject_name}
            onChange={e => {
              onDataChange('subject_name', e.target.value);
              const app = _.find(applications, app => app.subject_name === e.target.value);
              if (app) {
                onDataChange('set', app.set);
              } else {
                onDataChange('set', undefined);
              }

              onDataChange('question_id', '');
              onDataChange('answer', '');
              onDataChange('reason', '');
              onDataChange('attached_image', undefined);
            }}>
            <option value="">เลือกวิชา</option>
            {options}
          </select>
        </div>
        <div className="col required">
          <label
            style={errors.set ? { color: '#ea0c42' } : {}}>ชุดแบบทดสอบ</label>
          <div className="radio-group"
            style={errors.set ? { borderColor: '#ea0c42' } : {}}>
            <label className="s-flex gap-2">
              <input
                type="radio"
                disabled={!data.subject_name}
                name={`set_${index}`}
                checked={(data.set || currentApp.set) === 1}
                onChange={!data.subject_name
                  ? () => { }
                  : () => onDataChange('set', 1)} /> ชุดที่ 1
            </label>
            <label className="s-flex gap-2">
              <input
                type="radio"
                disabled={!data.subject_name}
                name={`set_${index}`}
                checked={(data.set || currentApp.set) === 2}
                onChange={!data.subject_name
                  ? () => { }
                  : () => onDataChange('set', 2)} /> ชุดที่ 2
            </label>
          </div>
        </div>
        <div className="col required">
          <label
            style={errors.question_id ? { color: '#ea0c42' } : {}}>ข้อที่ต้องการโต้แย้ง</label>
          <select
            style={errors.question_id ? { borderColor: '#ea0c42' } : {}}
            disabled={!data.subject_name || !data.set}
            value={data.question_id}
            onChange={e => onDataChange('question_id', e.target.value)}>
            <option value="">เลือกข้อ</option>
            {Array(questionCount).fill().map((_, index) =>
              <option key={index} value={index + 1}>{index + 1}</option>
            )}
          </select>
        </div>
        {!!data.question_id && (
          <>
            {questionType === 'number' ? (
              <div className="col -full required">
                <label style={errors.answer ? { color: '#ea0c42' } : {}}>คำตอบที่ถูกต้อง</label>
                <div className="flex flex-wrap gap-4 gap-y-2">
                  <label className="s-flex gap-2 no-x w-auto">
                    <input
                      type="text"
                      style={errors.answer ? { borderColor: '#ea0c42' } : {}}
                      placeholder="0000.00"
                      disabled={(data.answer || '') === 'unanswerable'}
                      value={(data.answer || '') === 'unanswerable' ? '' : (data.answer || '')}
                      onChange={(e) => {
                        let num = e.target.value;
                        if (isNaN(num)) return;
                        if (num > 10000) return;
                        if ((_.get(num.split('.'), 1) || '').length > 2) return;
                        onDataChange('answer', num);
                      }}
                      onBlur={() => onDataChange('answer', _.padStart(parseFloat(data.answer || '0').toFixed(2), 7, '0'))} />
                  </label>
                  <label className="s-flex gap-2 no-x w-auto">
                    <input
                      type="checkbox"
                      checked={data.answer === 'unanswerable'}
                      onChange={() => onDataChange('answer', data.answer === 'unanswerable' ? '' : 'unanswerable')} /> ไม่มีคำตอบที่ถูกต้อง
                  </label>
                </div>
              </div>
            ) : questionType === 'multiple' ? (
              <div className="col -full required">
                <label style={errors.answer ? { color: '#ea0c42' } : {}}>คำตอบที่ถูกต้อง</label>
                <div className="flex flex-wrap gap-4 gap-y-2">
                  <label className="s-flex gap-2 no-x w-auto">
                    <input
                      type="checkbox"
                      checked={data.answer.split(',').includes('111')}
                      onChange={() => _onAnswerSelect('111')} /> 111
                  </label>
                  <label className="s-flex gap-2 no-x w-auto">
                    <input
                      type="checkbox"
                      checked={data.answer.split(',').includes('112')}
                      onChange={() => _onAnswerSelect('112')} /> 112
                  </label>
                  <label className="s-flex gap-2 no-x w-auto">
                    <input
                      type="checkbox"
                      checked={data.answer.split(',').includes('121')}
                      onChange={() => _onAnswerSelect('121')} /> 121
                  </label>
                  <label className="s-flex gap-2 no-x w-auto">
                    <input
                      type="checkbox"
                      checked={data.answer.split(',').includes('122')}
                      onChange={() => _onAnswerSelect('122')} /> 122
                  </label>
                  <label className="s-flex gap-2 no-x w-auto">
                    <input
                      type="checkbox"
                      checked={data.answer.split(',').includes('211')}
                      onChange={() => _onAnswerSelect('211')} /> 211
                  </label>
                  <label className="s-flex gap-2 no-x w-auto">
                    <input
                      type="checkbox"
                      checked={data.answer.split(',').includes('212')}
                      onChange={() => _onAnswerSelect('212')} /> 212
                  </label>
                  <label className="s-flex gap-2 no-x w-auto">
                    <input
                      type="checkbox"
                      checked={data.answer.split(',').includes('221')}
                      onChange={() => _onAnswerSelect('221')} /> 221
                  </label>
                  <label className="s-flex gap-2 no-x w-auto">
                    <input
                      type="checkbox"
                      checked={data.answer.split(',').includes('222')}
                      onChange={() => _onAnswerSelect('222')} /> 222
                  </label>
                  <label className="s-flex gap-2 no-x w-auto">
                    <input
                      type="checkbox"
                      checked={data.answer === 'unanswerable'}
                      onChange={() => onDataChange('answer', data.answer === 'unanswerable' ? '' : 'unanswerable')} /> ไม่มีคำตอบที่ถูกต้อง
                  </label>
                </div>
              </div>
            ) : (
              <div className="col -full required">
                <label style={errors.answer ? { color: '#ea0c42' } : {}}>ตัวเลือกที่ถูกต้อง</label>
                <div className="flex flex-wrap gap-4 gap-y-2">
                  <label className="s-flex gap-2 no-x w-auto">
                    <input
                      type="checkbox"
                      checked={(data.answer || '').includes('1')}
                      onChange={() => _onAnswerSelect('1')} /> 1
                  </label>
                  <label className="s-flex gap-2 no-x w-auto">
                    <input
                      type="checkbox"
                      checked={(data.answer || '').includes('2')}
                      onChange={() => _onAnswerSelect('2')} /> 2
                  </label>
                  <label className="s-flex gap-2 no-x w-auto">
                    <input
                      type="checkbox"
                      checked={(data.answer || '').includes('3')}
                      onChange={() => _onAnswerSelect('3')} /> 3
                  </label>
                  <label className="s-flex gap-2 no-x w-auto">
                    <input
                      type="checkbox"
                      checked={(data.answer || '').includes('4')}
                      onChange={() => _onAnswerSelect('4')} /> 4
                  </label>
                  <label className="s-flex gap-2 no-x w-auto">
                    <input
                      type="checkbox"
                      checked={(data.answer || '').includes('5')}
                      onChange={() => _onAnswerSelect('5')} /> 5
                  </label>
                  <label className="s-flex gap-2 no-x w-auto">
                    <input
                      type="checkbox"
                      checked={data.answer === 'unanswerable'}
                      onChange={() => onDataChange('answer', data.answer === 'unanswerable' ? '' : 'unanswerable')} /> ไม่มีคำตอบที่ถูกต้อง
                  </label>
                </div>
              </div>
            )}
            <div className="col -full required">
              <label
                style={errors.reason ? { color: '#ea0c42' } : {}}>เหตุผลเพิ่มเติม</label>
              <textarea
                style={errors.reason ? { borderColor: '#ea0c42' } : {}}
                value={data.reason}
                onChange={e => {
                  const data = e.target.value;
                  if (data.trim().length >= 1) {
                    onDataChange('reason', data)
                  }
                }} />
            </div>
            <div className="col">
              <label className="flex flex-wrap w-auto items-center">
                <span className="mb-1">แนบภาพประกอบ &nbsp;</span>
                <small className="mb-1">(JPEG หรือ PNG ขนาดไม่เกิน 500KB)</small>
              </label>
              <input
                type="file"
                accept="image/*"
                onChange={_onAttachedImageChange} />
            </div>
          </>
        )}
      </div>
    </>
  );
};