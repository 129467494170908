/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { callAPI } from 'helpers';
import moment from 'moment';

// Components
import { Link, useParams, useNavigate } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ExamSlotInfo from 'components/profile/apply-exams/ExamSlotInfo';
import ExamSiteInfo from 'components/profile/apply-exams/ExamSiteInfo';

// Hooks
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Data
import { TGATTPATINFOS } from 'data/exam-days';
import enabled from 'data/enabled';
import disabilities from 'data/disabilities';

const getProvince = (province) => (['นนทบุรี', 'สมุทรปราการ', 'กรุงเทพมหานคร'].includes(province) ? 'กรุงเทพและปริมณฑล' : province);
const hasDifferentProvince = ({ site, places }) => getProvince(site.province) !== getProvince(_.get(places, '0.province'));

const TGATTPATDetail = () => {
  const { t } = useTranslation();
  const { day } = useParams();
  const navigate = useNavigate();
  const profile = useSelector((state) => _.get(state, 'app.user') || {});
  const { date, colorClassName, examSlots } = _.find(TGATTPATINFOS, ({ dayIndex }) => `${dayIndex}` === `${day}`);

  const [loading, setLoading] = useState(false);
  const [selections, setSelections] = useState([]);
  const [confirmations, setConfirmations] = useState([]);
  const [historyLogs, setHistoryLogs] = useState([]);
  const [siteChangeDisabled, setSiteChangeDisabled] = useState(false);
  const [siteQuotaExceeded, setSiteQuotaExceeded] = useState(false);
  const [expired, setExpired] = useState(false);

  const [examSites, setExamSites] = useState([]);
  const siteMap = _.keyBy(examSites, ({ exam_type, site_code }) => `${exam_type}-${site_code}`);
  const [currentStep, setCurrentStep] = useState();
  const [flow, setFlow] = useState();
  const [subjects, setSubjects] = useState([]);
  const [examType, setExamType] = useState();
  const [pbtPlaces, setPbtPlaces] = useState([]);
  const [cbtPlaces, setCbtPlaces] = useState([]);
  const [provinceConfirmed, setProvinceConfirmed] = useState(false);
  const [specialRequestMap, setSpecialRequestMap] = useState({});

  useEffect(() => {
    if (profile.exam_4y_alleged) { navigate('/profile/apply-exams/tgattpat/veto', { replace: true }); }
  }, [profile, navigate]);

  useEffect(() => {
    const fetchSummary = async () => {
      try {
        setLoading(true);
        const { result } = await callAPI({ url: '/applicants/me/apply-exam-summary/tgattpat' });
        const _expired = moment(result.timestamp).isAfter(enabled.tgattpat_apply_exams.expired);

        setSelections(_expired ? result.recent_confirmations : result.selections);
        setExamSites(result.exam_sites);
        setConfirmations(result.recent_confirmations);
        setHistoryLogs(result.history_logs);
        setSiteQuotaExceeded(
          _.get(
            _.maxBy(result.recent_confirmations, ({ site_confirm_id }) => site_confirm_id),
            'site_confirm_id'
          ) >= 3
        );
        setCurrentStep(_.find(result.selections, (sel) => sel.day === day) ? null : 1);
        setExpired(_expired);
        setProvinceConfirmed(_.get(_.find(result.selections, (sel) => sel.day === day), 'province_confirmed'));
        setLoading(false);

        const confirmedSubjectCount = _.sumBy(result.recent_confirmations, ({ subjects = [] }) => subjects.length);
        const totalSubjectCount = _.sumBy(result.selections, ({ subjects = [] }) => subjects.length);
        setSiteChangeDisabled(confirmedSubjectCount > 0 && confirmedSubjectCount !== totalSubjectCount);
      } catch (error) {
        console.log({ error });
        setLoading(false);
      }
    };
    fetchSummary();
  }, [day]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  const [cancelVisible, setCancelVisible] = useState(false);
  const [canceling, setCanceling] = useState(false);
  const _onSelectionCancel = async () => {
    if (canceling) return;
    try {
      setCanceling(true);
      await callAPI({
        method: 'DELETE',
        url: `/applicants/me/exam-selections/${day}`,
      });
      setCanceling(false);
      navigate('/profile/apply-exams/tgattpat');
    } catch (error) {
      console.log({ error });
      setCanceling(false);
    }
  };

  const _hasSitesChanged = (confirmation, selection) => {
    if (!confirmation && selection) return false;
    return Array(5)
      .fill()
      .reduce((array, __, i) => array.concat([`place_${i + 1}`, `cbt_place_${i + 1}`]), ['province_confirmed'])
      .some((field) => {
        if (field === 'province_confirmed') return Boolean(confirmation[field]) !== Boolean(selection[field]);
        return confirmation[field] !== selection[field]
      });
  };
  const someSitesChanged = confirmations.some((confirmation) =>
    _hasSitesChanged(
      confirmation,
      _.find(selections, ({ day }) => confirmation.day === day)
    )
  );

  useEffect(() => {
    const classNames = document.getElementById('root').className.split(' ');
    if (loading) {
      document.getElementById('root').className = _.uniq(classNames.concat(['is-loading'])).join(' ');
    } else {
      document.getElementById('root').className = classNames.filter((name) => name !== 'is-loading').join(' ');
    }
  }, [loading]);

  if (loading) {
    return (
      <main className='site-body'>
        <div className='t-loading' />
      </main>
    );
  }

  const currentSelection = _.find(selections, (sel) => sel.day === `${day}`);
  if (currentSelection) {
    if (currentStep) {
      const recentSubjects = currentSelection.subjects.map((_key) => _.find(examSlots, (slot) => slot.subjectKey === _key));
      const specialRequests = currentSelection.special_requests;

      const _pbtPlaces = _.compact(
        Array(5)
          .fill()
          .map((__, i) => _.find(examSites, ({ site_code }) => site_code === currentSelection[`place_${i + 1}`]))
      );
      const _cbtPlaces = _.compact(
        Array(5)
          .fill()
          .map((__, i) => _.find(examSites, ({ site_code }) => site_code === currentSelection[`cbt_place_${i + 1}`]))
      );

      const flowTitle = {
        'add_subject': t('เพิ่มวิชาสอบ'),
        'update_sites': t('แก้ไขสนามสอบ')
      }[flow];

      return (
        <main className='site-body'>
          <div className='t-form'>
            <h1>{flowTitle}</h1>
            <div className={cn('exam-box exam-choose-step', { [colorClassName]: true })}>
              <div className='exam-box-header'>
                <div className='s-flex -between'>
                  <div className='_heading'>
                    <span className='num'>{day}</span>
                    <b>{date}</b>
                  </div>
                </div>
              </div>
              <div className='exam-box-body'>
                {currentStep === 1 ? (
                  <ExamSubjectSelection
                    day={day}
                    colorClassName={colorClassName}
                    examSlots={examSlots}
                    specialRequests={specialRequests}
                    selectedSubjects={recentSubjects}
                    onNext={(subjects, specialRequestMap) => {
                      setSubjects(subjects);
                      setSpecialRequestMap(specialRequestMap);
                      setCurrentStep(3);
                    }}
                  />
                ) : currentStep === 2 ? (
                  <ExamSiteSelection
                    day={day}
                    examType={examType || currentSelection.exam_type}
                    examSites={examSites}
                    selectedPbtPlaces={_pbtPlaces.length ? _pbtPlaces : pbtPlaces}
                    selectedCbtPlaces={_cbtPlaces.length ? _cbtPlaces : cbtPlaces}
                    provinceConfirmed={provinceConfirmed}
                    onBack={() => setCurrentStep()}
                    onNext={(examType, pbtPlaces, cbtPlaces = [], provinceConfirmed) => {
                      setExamType(examType);
                      setPbtPlaces(pbtPlaces);
                      setCbtPlaces(cbtPlaces);
                      setProvinceConfirmed(provinceConfirmed);
                      setCurrentStep(3);
                    }}
                  />
                ) : currentStep === 3 ? (
                  <ExamSelectionSummary
                    day={day}
                    colorClassName={colorClassName}
                    examType={examType}
                    recentSubjects={recentSubjects}
                    subjects={subjects}
                    pbtPlaces={pbtPlaces.length ? pbtPlaces : _pbtPlaces}
                    cbtPlaces={examType === 'pbt' ? [] : cbtPlaces.length ? cbtPlaces : _cbtPlaces}
                    provinceConfirmed={provinceConfirmed}
                    specialRequestMap={specialRequestMap}
                    onBack={() => setCurrentStep(flow === 'add_subject' ? 1 : flow === 'update_sites' ? 2 : null)} />
                ) : null}
              </div>
            </div>
          </div>
        </main>
      );
    }

    return (
      <main className='site-body'>
        <div className='t-form'>
          <h1>{t('รายละเอียดการสมัครสอบ')}</h1>
          <div className={cn('exam-box exam-choose-step', { [colorClassName]: true })}>
            <div className='exam-box-header'>
              <div className='s-flex -between'>
                <div className='_heading'>
                  <span className='num'>{day}</span>
                  <b>{date}</b>
                </div>
              </div>
            </div>
            <div className='exam-box-body'>
              {TGATTPATINFOS.map(({ dayIndex, colorClassName, examSlots }, index) => {
                const selection = _.find(selections, (sel) => sel.day === `${dayIndex}` && sel.day === day);
                const confirmation = _.find(confirmations, (sel) => sel.day === `${dayIndex}` && sel.day === day && sel.order_status === 'paid');
                const tgatRedeemed = historyLogs.some(({ tgat_redeemed }) => tgat_redeemed);

                if (!selection) return null;
                if (expired && dayIndex === 2 && tgatRedeemed) {
                  const confirmationGroups = _.groupBy(historyLogs.filter(({ order_status, day }) => order_status === 'paid' && day === '2'), 'day');
                  const paidConfirmation = _.get(Object.keys(confirmationGroups).reduce((array, key) => array.concat(_.last(confirmationGroups[key])), []), 0);
                  const unpaidConfirmation = _.maxBy(historyLogs.filter(({ day }) => day === '2'), ({ apply_id, site_confirm_id }) => `${apply_id}-${site_confirm_id}`);
                  const recent = paidConfirmation || unpaidConfirmation || {};
                  const subjects = (recent.subjects || []).concat(['TGAT']);

                  return (
                    <>
                      <div className={cn('exam-box', { [colorClassName]: true })}>
                        <ul className='exam-box-list'>
                          {examSlots
                            .filter(({ subjectKey }) => subjects.includes(subjectKey))
                            .map((slot, slotIndex, all) => {
                              return (
                                <ExamSlotInfo
                                  key={slotIndex}
                                  examType={recent.exam_type}
                                  specialRequests={recent.special_requests}
                                  confirmations={confirmation ? [confirmation] : []}
                                  slot={slot}
                                  footer={null}
                                />
                              );
                            })}
                        </ul>
                      </div>
                      <div className={cn('exam-box', { [colorClassName]: true })}>
                        <ul className='exam-box-list'>
                          <ExamSiteInfo
                            examType={_.get(recent, 'exam_type')}
                            data={recent}
                            siteMap={siteMap} />
                        </ul>
                      </div>
                    </>
                  );
                }

                return (
                  <>
                    <div className={cn('exam-box', { [colorClassName]: true })}>
                      <ul className='exam-box-list'>
                        {examSlots
                          .filter(({ subjectKey }) => selection.subjects.includes(subjectKey))
                          .map((slot, slotIndex, all) => {
                            return (
                              <ExamSlotInfo
                                key={slotIndex}
                                examType={selection.exam_type}
                                specialRequests={selection.special_requests}
                                confirmations={confirmation ? [confirmation] : []}
                                slot={slot}
                                footer={
                                  expired ? null : examSlots.length === (selection.subjects || []).length ? null : someSitesChanged ? (
                                    <li>
                                      <h4 className='text-center color-orange m-0 _heading'>
                                        <b>
                                          {t('ไม่สามารถเพิ่มวิชาสอบได้')}
                                          <br />
                                          {t('กรุณายืนยันการแก้ไขสนามสอบก่อน')}
                                        </b>
                                      </h4>
                                    </li>
                                  ) : (examSlots.length !== (selection.subjects || []).length && slotIndex === all.length - 1) ? (
                                    <li>
                                      <div className='text-center'>
                                        <a
                                          onClick={() => {
                                            setCurrentStep(1);
                                            setFlow('add_subject');
                                          }}
                                          className='btn-exam-underline color-blue cursor-pointer'
                                        >
                                          {t('เพิ่มวิชาสอบ')}
                                        </a>
                                        {Boolean(confirmation) && <div className='color-gray _heading'>{t('โดยชำระค่าสมัครเพิ่มเติม')}</div>}
                                      </div>
                                    </li>
                                  ) : null
                                }
                              />
                            );
                          })}
                      </ul>
                    </div>
                    <div className={cn('exam-box', { [colorClassName]: true })}>
                      <ul className='exam-box-list'>
                        <ExamSiteInfo
                          examType={_.get(_.find(selections, (sel) => sel.day === `${dayIndex}` && sel.day === day), 'exam_type')}
                          data={_.find(selections, (sel) => sel.day === `${dayIndex}` && sel.day === day)}
                          siteMap={siteMap} />
                        {expired ? null : siteChangeDisabled ? (
                          <li>
                            <h4 className='text-center color-orange m-0 _heading'>
                              <b>
                                {t('ไม่สามารถแก้ไขสนามสอบได้')}
                                <br />
                                {t('กรุณายืนยันการสมัครก่อน')}
                              </b>
                            </h4>
                          </li>
                        ) : siteQuotaExceeded ? (
                          <li>
                            <h4 className='text-center color-orange m-0'>
                              <b>
                                {t('ผู้สมัครแก้ไขสนามสอบครบ 3 ครั้งแล้ว')}
                                <br />
                                {t('ไม่สามารถเปลี่ยนแปลงได้อีก')}
                              </b>
                            </h4>
                          </li>
                        ) : (
                          <li>
                            <div className='text-center'>
                              <a
                                onClick={() => {
                                  setCurrentStep(2);
                                  setFlow('update_sites');
                                }}
                                className='btn-exam-underline color-blue cursor-pointer'
                              >
                                {t('แก้ไขสนามสอบ')}
                              </a>
                              {Boolean(confirmation) && <div className='color-gray _heading'>{t('แก้ไขได้ทั้งหมด 3 ครั้ง ไม่มีค่าใช้จ่าย')}</div>}
                            </div>
                          </li>
                        )}
                      </ul>
                    </div>
                    {(_.get(confirmation, 'subjects') || []).length !== selection.subjects.length && (
                      <div className={cn('r-more', { active: cancelVisible })}>
                        <h4 className='text-center color-orange' onClick={() => setCancelVisible((_visible) => !_visible)}>
                          <b>{t('ยกเลิกการแก้ไข?')}</b> <i className='i-more'></i>
                        </h4>
                        <div className='more'>
                          <div className='text-center'>
                            {t('หากต้องการกลับไปที่การสมัครครั้งที่ผ่านมา')} <br />
                            {t('ให้คลิกเลือกยกเลิกการแก้ไข')}
                          </div>
                          <div className='_space' />
                          <div className='action'>
                            <a onClick={_onSelectionCancel} className={cn('btn-eaxm-cancel cursor-pointer _heading', { loading: canceling, disabled: canceling })}>
                              {t('ยกเลิกการแก้ไข')}
                            </a>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          </div>
          <div className='_space' />
          <div className='text-center'>
            <Link to='/profile/apply-exams/tgattpat' className='color-gray _heading'>
              <i className='i-arrow-left'></i> {t('ย้อนกลับ')}
            </Link>
          </div>
        </div>
      </main>
    );
  }

  if (expired) return (
    <main className='site-body'>
      <div className='t-form'>
        <h1>{t('หมดเวลาสมัครสอบแล้ว')}</h1>
      </div>
    </main>
  );

  return (
    <main className='site-body'>
      <div className='t-form'>
        <h1>{t('เลือกวิชาสอบและสนามสอบ')}</h1>
        <div className={cn('exam-box exam-choose-step', { [colorClassName]: true })}>
          <div className='exam-box-header'>
            <div className='s-flex -between'>
              <div className='_heading'>
                <span className='num'>{day}</span>
                <b>{date}</b>
              </div>
            </div>
          </div>
          <div className='exam-box-body'>
            <ul className='t-step'>
              <li className={cn({ active: currentStep === 1 })}>
                <span>{t('เลือกวิชาสอบ')}</span>
              </li>
              <li className={cn({ active: currentStep === 2 })}>
                <span>{t('เลือกสนามสอบ')}</span>
              </li>
              <li className={cn({ active: currentStep === 3 })}>
                <span>{t('ตรวจสอบข้อมูล')}</span>
              </li>
            </ul>
            {currentStep === 1 && (
              <ExamSubjectSelection
                day={day}
                colorClassName={colorClassName}
                examSlots={examSlots}
                onNext={(subjects, specialRequestMap) => {
                  setSubjects(subjects);
                  setSpecialRequestMap(specialRequestMap);
                  setCurrentStep(2);
                }}
              />
            )}
            {currentStep === 2 && (
              <ExamSiteSelection
                day={day}
                examType={examType}
                examSites={examSites}
                selectedPbtPlaces={pbtPlaces}
                selectedCbtPlaces={cbtPlaces}
                provinceConfirmed={provinceConfirmed}
                onBack={() => setCurrentStep(1)}
                onNext={(examType, pbtPlaces, cbtPlaces = [], provinceConfirmed) => {
                  setExamType(examType);
                  setPbtPlaces(pbtPlaces);
                  setCbtPlaces(cbtPlaces);
                  setProvinceConfirmed(provinceConfirmed);
                  setCurrentStep(3);
                }}
              />
            )}
            {currentStep === 3 && (
              <ExamSelectionSummary
                day={day}
                colorClassName={colorClassName}
                examType={examType}
                subjects={subjects}
                pbtPlaces={pbtPlaces}
                cbtPlaces={cbtPlaces}
                provinceConfirmed={provinceConfirmed}
                specialRequestMap={specialRequestMap}
                onBack={() => setCurrentStep(2)} />
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default TGATTPATDetail;

const ExamSubjectSelection = ({ examSlots, colorClassName, selectedSubjects = [], specialRequests = {}, onNext }) => {
  const { t } = useTranslation();
  const profile = useSelector((state) => _.get(state, 'app.user') || {});

  const [subjects, setSubjects] = useState(selectedSubjects);
  const [specialRequestMap, setSpecialRequestMap] = useState({});
  const onCheck = (subject) => {
    const { subjectCode, subjectKey } = subject;
    setSubjects((_initialSubjects) => {
      const subjectCodes = _initialSubjects.map(({ subjectCode }) => subjectCode);
      if (subjectCodes.includes(subjectCode)) return _initialSubjects.filter((_subject) => _subject.subjectCode !== subjectCode);
      return _initialSubjects.concat([subject]);
    });

    if (profile.special_request) {
      const _key = subjectKey;
      setSpecialRequestMap(_map => ({
        ..._map,
        [_key]: _map[_key] ? null : profile.special_request
      }));
    }
  }
  const disabled = !(subjects.length - selectedSubjects.length);

  return (
    <>
      {Boolean(!selectedSubjects.length) && <span className='color-blue _heading'>{t('เลือกวิชาสอบและสนามสอบ')}</span>}
      <div className='choose-subject'>
        {/* วิชาสอบที่สมัครไปแล้ว */}
        {examSlots
          .filter((subject) => selectedSubjects.map(({ subjectKey }) => subjectKey).includes(subject.subjectKey))
          .map((subject, index) => (
            <div index={index} className={cn('exam-box', { [colorClassName]: true })}>
              <ul className='exam-box-list'>
                <ExamSlotInfo
                  slot={subject}
                  specialRequests={specialRequests} />
              </ul>
            </div>
          ))}
        {/* วิชาสอบที่ยังไม่สมัคร และไม่เป็นวิชาสอบภาษาต่างประเทศ */}
        {examSlots
          .filter((subject) => !selectedSubjects.map(({ subjectKey }) => subjectKey).includes(subject.subjectKey))
          .map((subject, index) => {
            const { time, subjectKey, subjectName, subjectCode, subSubjects } = subject;
            const checked = subjects.some((_subject) => _subject.subjectKey === subjectKey);
            const specialRequest = specialRequestMap[subjectKey];
            const specialRequestIndex = _.findIndex(disabilities, text => text === specialRequest);

            return (
              <div key={index} className='xm-box'>
                <div className='num'>
                  <b>{index + 1}</b>
                </div>
                <label>
                  <input type='checkbox' checked={checked} onChange={() => onCheck(subject)} />
                  <ul>
                    <li>
                      {t('รหัสวิชา')} <b>{subjectCode}</b>
                    </li>
                    <li>
                      {t('ชื่อวิชา')}{' '}
                      <b>
                        {subjectKey} {subjectName}
                      </b>
                    </li>
                    <li>
                      {t('เวลา')} <b>{time}</b>
                    </li>
                  </ul>
                  {Boolean((subSubjects || []).length) && (
                    <>
                      <div className='color-blue remark'>* {t('สำหรับผู้ต้องการสอบวิชาเหล่านี้')}</div>
                      <hr className='hr-dashed' />
                      <ul className='list-style-disc'>
                        {subSubjects.map((sub) => (
                          <li key={sub.subjectCode}>
                            {t('รหัสวิชา')} <b>{sub.subjectCode}</b>
                            <br />
                            {t('ชื่อวิชา')}{' '}
                            <b>
                              {sub.subjectKey} {sub.subjectName}
                            </b>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </label>
                {checked && Boolean(profile.special_request) && (
                  <>
                    <hr className="hr-dashed" />
                    <h4 className="color-orange mb-1 flex items-center gap-1">
                      <i className="i-disability"></i>
                      <span>{t('ความช่วยเหลือพิเศษ')}</span>
                    </h4>
                    <select
                      required
                      value={specialRequestIndex}
                      onChange={(e) => setSpecialRequestMap(_map => ({ ..._map, [subjectKey]: disabilities[e.target.value] }))}>
                      <option>ไม่ต้องการความช่วยเหลือพิเศษ</option>
                      {disabilities.map((text, index) => (
                        <option key={index} value={index}>
                          {text}
                        </option>
                      ))}
                    </select>
                  </>
                )}
              </div>
            );
          })}
      </div>
      <div className='action-step'>
        <Link to='/profile/apply-exams/tgattpat' className='btn-step-back _heading'>
          <IconChevronLeft />
          {t('ย้อนกลับ')}
        </Link>
        <a
          className={cn('btn-step-next btn-main', {
            'cursor-pointer': !disabled,
            disabled,
          })}
          onClick={disabled ? () => { } : () => onNext(subjects, specialRequestMap)}
        >
          {t('ไปหน้าถัดไป')}
          <IconChevronRight />
        </a>
      </div>
    </>
  );
};

const SiteSelector = ({ day, profile, examType, examSites, droppableId, places, onPlacesChange, checked, onCheckedChange, provinceConfirmed, onProvinceConfirm }) => {
  const dragDropRef = useRef();
  const { t } = useTranslation();

  const [height, setHeight] = useState(0);

  const _onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) return;
    onPlacesChange((places) => {
      const [removed] = places.splice(result.source.index, 1);
      places.splice(result.destination.index, 0, removed);
      return places;
    });
  };

  const [keyword, setKeyword] = useState('');
  const _onSelect = (site) => {
    onPlacesChange((_places) => (_places.some((_place) => _place.site_code === site.site_code) ? _places.filter((_place) => _place.site_code !== site.site_code) : _places.concat([site])));
  };

  const filteredSites = examSites.filter((site) => {
    const baseLogic = (!keyword || site.school_name.includes(keyword) || site.province.includes(keyword)) && site.exam_type === examType;
    switch (examType) {
      case 'cbt':
        if (profile.special_request) return false;
        return baseLogic && Boolean(_.get(site, `info_by_days.day${day}.cbt_seats`));
      case 'pbt':
        // if (profile.special_request) return baseLogic && Boolean(_.get(site, `info_by_days.day${day}.special_seats`));
        return baseLogic && Boolean(_.get(site, `info_by_days.day${day}.seats`));
      default:
        return baseLogic;
    }
  });

  return (
    <>
      <ul ref={dragDropRef} className='r-choose -exam -drag' style={height ? { height } : null}>
        <DragDropContext
          onBeforeDragStart={() => setHeight((dragDropRef.current || {}).offsetHeight || 0)}
          onDragEnd={(result) => {
            _onDragEnd(result);
            setHeight(0);
          }}
        >
          <Droppable droppableId={droppableId}>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {(places || []).map((site, index) => {
                  return (
                    <Draggable key={site.site_code} draggableId={site.site_code} index={index}>
                      {(provided) => {
                        const warning = hasDifferentProvince({ site, places });
                        return (
                          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={{ useSelect: 'none', ...provided.draggableProps.style }}>
                            <li>
                              <i className='i-drag -sky' />
                              <label className={cn({ '-error': warning })}>
                                <div className='num'>
                                  <b>{index + 1}</b>
                                </div>
                                <div className='icon'>
                                  <i
                                    className={cn({
                                      'i-computer': site.exam_type === 'cbt',
                                      'i-paper': site.exam_type === 'pbt',
                                    })}
                                  />
                                </div>
                                <strong>{site.school_name}</strong>
                                <span>{site.province}</span>
                                {warning && (
                                  <>
                                    <hr className='hr-dashed' />
                                    <span className='remark'>
                                      <b>{t('หมายเหตุ : สนามสอบข้ามจังหวัด')}</b>
                                    </span>
                                  </>
                                )}
                              </label>
                              <i className='i-exam-del cursor-pointer' onClick={() => onPlacesChange((_places) => _places.filter((_place) => _place.site_code !== site.site_code))} />
                            </li>
                            {Boolean(places.length) && !index && (
                              <label id="province-confirm">
                                <input type="checkbox" checked={provinceConfirmed} onChange={() => onProvinceConfirm(_confirmed => !_confirmed)} />
                                <h4 className="color-orange">
                                  <b>{t('เลือกสนามสอบใน')} <u>{t('จังหวัด')}{getProvince(places[0].province)}</u> {t('เป็นหลัก')}</b>
                                  <small className="block">
                                    {t('กรณีสนามสอบที่เลือกไว้ใน')} <u>{t('จังหวัด')}{getProvince(places[0].province)}</u> {t('เต็มและมีการเปิดสนามสอบใหม่ ให้เลือกสนามสอบที่เปิดใหม่นี้ก่อนเลือกสนามสอบในจังหวัดอื่น')}
                                  </small>
                                </h4>
                              </label>
                            )}
                          </div>
                        );
                      }}
                    </Draggable>
                  );
                })}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </ul>
      {Array(5 - places.length)
        .fill()
        .map((__, index) => (
          <div key={index} className='t-row'>
            <div className='col -full'>
              <input type='text' placeholder={`${t('ลำดับที่')} ${places.length + index + 1} * -`} disabled />
            </div>
          </div>
        ))}
      <p>{t('ค้นหาสนามสอบ โดยเลือกเรียงตามลำดับที่ผู้สมัครต้องการเข้าสอบ จากมากที่สุดไปน้อยที่สุด')}</p>
      <div className='t-search'>
        <div className='input-group'>
          <input type='text' placeholder={t('ค้นหาสนามสอบ')} onChange={(e) => setKeyword(e.target.value.trim())} value={keyword} />
          <i className='i-search'></i>
        </div>
      </div>
      {Boolean(keyword) && (
        <div className='t-result'>
          <h2>
            <strong>{t('ผลการค้นหา')}</strong>
            <span className='sep'>•</span>
            <b>
              {(filteredSites || []).length} {t('สนามสอบ')}
            </b>
            <i className='i-close' onClick={() => setKeyword('')} />
          </h2>
          <ul className='eaxm-field-list' style={{ overflowY: 'auto', maxHeight: '450px' }}>
            {filteredSites.map((site) => {
              const active = places.some((place) => place.site_code === site.site_code);
              const selectable = places.length < 5 || active;
              return (
                <li key={site.site_code}>
                  <a className={cn({ 'cursor-pointer': selectable })} onClick={selectable ? () => _onSelect(site) : () => { }}>
                    <div className='icon'>
                      <i
                        className={cn({
                          'i-computer i-20': site.exam_type === 'cbt',
                          'i-paper i-20': site.exam_type === 'pbt',
                        })}
                      />
                    </div>
                    <div className='field-info'>
                      <h4>
                        {site.school_name}
                        <br />
                        <small>{site.province}</small>
                      </h4>
                    </div>
                    {selectable && (
                      <div className='choose'>
                        <i className={cn('i-checked', { active })} />
                      </div>
                    )}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      )}
      {places.some((site) => getProvince(site.province) !== getProvince(places[0].province)) && (
        <>
          <div className='_space' />
          <label className='exam-comfirm-cross'>
            <input type='checkbox' checked={checked} onChange={onCheckedChange} />
            <span className='color-orange'>{t('ยืนยันเลือกสนามสอบข้ามจังหวัด')}</span>
          </label>
        </>
      )}
    </>
  );
};

const isDisabled = (places, checked) => {
  return !places.length || (!checked && places.some((site) => hasDifferentProvince({ site, places })));
};
const ExamSiteSelection = ({ day, examType: _examType, examSites, selectedPbtPlaces = [], selectedCbtPlaces = [], provinceConfirmed: _confirmed, onBack, onNext }) => {
  const { t } = useTranslation();
  const profile = useSelector((state) => _.get(state, 'app.user') || {});

  const [examType, setExamType] = useState(_examType || 'pbt');
  const [pbtPlaces, setPbtPlaces] = useState(selectedPbtPlaces);
  const [pbtChecked, setPbtChecked] = useState(false);
  const [cbtPlaces, setCbtPlaces] = useState(selectedCbtPlaces);
  const [cbtChecked, setCbtChecked] = useState(false);
  const [provinceConfirmed, setProvinceConfirmed] = useState(_confirmed);

  const pbtSites = examSites.filter((site) => site.exam_type === 'pbt' && Boolean(_.get(site, `info_by_days.day${day}.seats`)));
  // const pbtSites = examSites.filter((site) => site.exam_type === 'pbt' && Boolean(_.get(site, `info_by_days.day${day}.${profile.special_request ? 'special_seats' : 'seats'}`)));
  const firstSiteProvinceCount = pbtSites.filter(({ province }) => getProvince(province) === getProvince(_.get(pbtPlaces, '0.province'))).length;
  const minSitesSelected = pbtPlaces.length < 5 && pbtPlaces.filter((place) => _.get(place, 'province') === _.get(pbtPlaces, '0.province')).length < firstSiteProvinceCount;
  const nextDisabled = isDisabled(pbtPlaces, pbtChecked) || (examType === 'cbt' && isDisabled(cbtPlaces, cbtChecked));

  const _onNext = () => {
    if (minSitesSelected) {
      if (firstSiteProvinceCount >= 5) {
        alert('กรุณาเลือกสนามสอบให้ครบทั้ง 5 ลำดับ');
      } else {
        alert(`กรุณาเลือกทุกสนามสอบในจังหวัด${_.get(pbtPlaces, '0.province')}`);
      }
    } else {
      onNext(examType, pbtPlaces, examType === 'pbt' ? [] : cbtPlaces, provinceConfirmed);
    }
  };

  return (
    <>
      <h4 className='title'>{t('เลือกประเภทข้อสอบ')}</h4>
      <div className='exam-type'>
        <div className='inline-group'>
          <i className='i-paper'></i>
          <label htmlFor='type1'>{t('กระดาษ')}</label>
          <input type='radio' name='type' id='type1' checked={examType === 'pbt'} onChange={() => setExamType('pbt')} />
        </div>
        {!profile.special_request && (
          <div className='inline-group'>
            <i className='i-computer'></i>
            <label htmlFor='type2'>{t('คอมพิวเตอร์')}</label>
            <input type='radio' name='type' id='type2' checked={examType === 'cbt'} onChange={() => setExamType('cbt')} />
          </div>
        )}
      </div>
      <hr className='hr-dashed' />
      <h4 className='title'>{t('เลือกสนามสอบ')}</h4>
      {examType === 'pbt' ? (
        <div>
          <p>{t('กรุณาเลือกสนามสอบให้ครบ 5 ลำดับ ยกเว้นกรณีที่จังหวัดในลำดับแรกมีสนามสอบน้อยกว่า 5 สนามสอบ ให้เลือกทุกสนามสอบในจังหวัดนั้นแทน')}</p>
          <SiteSelector
            droppableId='droppable'
            day={day}
            examType='pbt'
            profile={profile}
            examSites={examSites}
            places={pbtPlaces}
            onPlacesChange={setPbtPlaces}
            checked={pbtChecked}
            onCheckedChange={() => setPbtChecked((_checked) => !_checked)}
            onProvinceConfirm={setProvinceConfirmed}
            provinceConfirmed={provinceConfirmed} />
        </div>
      ) : (
        <>
          <hr className='hr-dashed' />
          <div className='title'>1. {t('สนามสอบคอมพิวเตอร์')}</div>
          <SiteSelector
            droppableId='cbt_droppable'
            day={day}
            examType='cbt'
            profile={profile}
            examSites={examSites}
            places={cbtPlaces}
            onPlacesChange={setCbtPlaces}
            checked={cbtChecked}
            onCheckedChange={() => setCbtChecked((_checked) => !_checked)}
            onProvinceConfirm={setProvinceConfirmed}
            provinceConfirmed={provinceConfirmed} />
          <hr className='hr-dashed' />
          <div className='title'>2. {t('สนามสอบกระดาษ')}</div>
          <p className='subtitle color-orange _heading'>{t('ต้องเลือกเผื่อกรณีสนามสอบคอมพิวเตอร์เต็ม')}</p>
          <p>{t('กรุณาเลือกสนามสอบให้ครบ 5 ลำดับ ยกเว้นกรณีที่จังหวัดในลำดับแรกมีสนามสอบน้อยกว่า 5 สนามสอบ ให้เลือกทุกสนามสอบในจังหวัดนั้นแทน')}</p>
          <SiteSelector
            droppableId='pbt_droppable'
            day={day}
            examType='pbt'
            profile={profile}
            examSites={examSites}
            places={pbtPlaces}
            onPlacesChange={setPbtPlaces}
            checked={pbtChecked}
            onCheckedChange={() => setPbtChecked((_checked) => !_checked)}
            onProvinceConfirm={setProvinceConfirmed}
            provinceConfirmed={provinceConfirmed} />
        </>
      )}
      <div className='action-step'>
        <a className='btn-step-back _heading cursor-pointer' onClick={onBack}>
          <IconChevronLeft />
          {t('ย้อนกลับ')}
        </a>
        <a onClick={nextDisabled ? () => { } : () => _onNext()} className={cn('btn-step-next btn-main cursor-pointer', { disabled: nextDisabled || minSitesSelected })}>
          {t('ไปหน้าถัดไป')}
          <IconChevronRight />
        </a>
      </div>
    </>
  );
};

const ExamSelectionSummary = ({ day, colorClassName, subjects, recentSubjects = [], examType, pbtPlaces, cbtPlaces, specialRequestMap, provinceConfirmed, onBack, onNext }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const pbtCounter = _.countBy(pbtPlaces, 'province');
  const cbtCounter = _.countBy(cbtPlaces, 'province');

  const [place_1, place_2, place_3, place_4, place_5] = pbtPlaces.map(({ site_code }) => site_code);
  const [cbt_place_1, cbt_place_2, cbt_place_3, cbt_place_4, cbt_place_5] = cbtPlaces.map(({ site_code }) => site_code);
  const [submitting, setSubmitting] = useState(false);
  const _onSubmit = async () => {
    try {
      setSubmitting(true);
      await callAPI({
        method: 'POST',
        url: `/applicants/me/exam-selections/${day}`,
        body: {
          subjects: subjects.map(({ subjectKey }) => subjectKey),
          exam_type: examType,

          place_1,
          place_2,
          place_3,
          place_4,
          place_5,

          cbt_place_1,
          cbt_place_2,
          cbt_place_3,
          cbt_place_4,
          cbt_place_5,

          special_requests: specialRequestMap,
          province_confirmed: provinceConfirmed
        },
      });
      setSubmitting(false);
      navigate('/profile/apply-exams/tgattpat');
    } catch (error) {
      console.error(new Error(`Error while trying to submit: ${error.message}`));
      setSubmitting(false);
    }
  };

  const newSubjects = subjects.filter((subjectKey) => !recentSubjects.includes(subjectKey));
  return (
    <>
      {(newSubjects || []).map((subject, index) => {
        const specialRequestKey = subject.subjectKey;
        return (
          <>
            <div className={cn('exam-box', { [colorClassName]: true })}>
              <ul className='exam-box-list'>
                <li key={subject.subjectCode}>
                  <div className='_heading color-blue'>
                    <b>{'วิชาสอบ'}</b>
                  </div>
                  <ul className='list-style-none'>
                    <li>
                      {t('รหัสวิชา')} <b>{subject.subjectCode}</b>
                    </li>
                    <li>
                      {t('ชื่อวิชา')}{' '}
                      <b>
                        {subject.subjectKey} {subject.subjectName}
                      </b>
                    </li>
                    <li>
                      {t('เวลา')} <b>{subject.time}</b>
                    </li>
                    {examType === 'pbt' ? (
                      <li>
                        {t('ประเภทข้อสอบ')} <i className='i-paper' />
                        <b>{t('กระดาษ')}</b>
                      </li>
                    ) : examType === 'cbt' ? (
                      <li>
                        {t('ประเภทข้อสอบ')} <i className='i-computer' />
                        <b>{t('คอมพิวเตอร์')}</b>
                      </li>
                    ) : null}
                  </ul>
                  {Boolean((subject.subSubjects || []).length) && (
                    <>
                      <span className='remark color-blue'>*{t('สำหรับผู้ต้องการสอบวิชาเหล่านี้')}</span>
                      <hr className='hr-dashed' />
                      <ul className='list-style-disc'>
                        {subject.subSubjects.map(({ subjectCode, subjectKey, subjectName }) => (
                          <li key={subjectCode}>
                            {t('รหัสวิชา')} <b>{subjectCode}</b>
                            <br />
                            {t('ชื่อวิชา')}{' '}
                            <b>
                              {subjectKey} {subjectName}
                            </b>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                  {specialRequestMap[specialRequestKey] && (
                    <>
                      <hr className="hr-dashed" />
                      <h4 className="color-orange m-0 flex items-center gap-1">
                        <i className="i-disability"></i>
                        <small><b>{specialRequestMap[specialRequestKey]}</b></small>
                      </h4>
                    </>
                  )}
                </li>
              </ul>
            </div>
            {index < subjects.length - 1 && <div className='_space' />}
          </>
        );
      })}
      <div className={cn('exam-box', { [colorClassName]: true })}>
        <ul className='exam-box-list'>
          <li>
            {examType === 'cbt' && (
              <>
                <div className='_heading color-blue'>
                  <b>{t('สนามสอบคอมพิวเตอร์')}</b>
                </div>
                <ul className='exam-venues'>
                  {(cbtPlaces || []).map((site, index) => {
                    const warning = hasDifferentProvince({ counter: cbtCounter, places: cbtPlaces, site });
                    return (
                      <li key={index} className={cn({ '-error': warning })}>
                        {t('ลำดับที่')} {index + 1}
                        <i className='i-computer' />
                        <div className='col'>
                          <b>
                            {site.school_name} <br />
                            {site.province}
                          </b>
                          {warning && (
                            <>
                              <br />
                              <span>{t('หมายเหตุ : สนามสอบข้ามจังหวัด')}</span>
                            </>
                          )}
                        </div>
                      </li>
                    );
                  })}
                  {Array(5 - cbtPlaces.length)
                    .fill()
                    .map((__, index) => (
                      <li key={index}>
                        {t('ลำดับที่')} {cbtPlaces.length + index + 1}
                        <span>-</span>
                      </li>
                    ))}
                </ul>
              </>
            )}
            <div className='_heading color-blue'>
              <b>
                {t('สนามสอบกระดาษ')}
                {examType === 'cbt' && ` (${t('สำรอง')})`}
              </b>
            </div>
            <ul className='exam-venues'>
              {(pbtPlaces || []).map((site, index) => {
                const warning = hasDifferentProvince({ counter: pbtCounter, places: pbtPlaces, site });
                return (
                  <li key={index} className={cn({ '-error': warning })}>
                    {t('ลำดับที่')} {index + 1}
                    <i className='i-paper' />
                    <div className='col'>
                      <b>
                        {site.school_name} <br />
                        {site.province}
                      </b>
                      {warning && (
                        <>
                          <br />
                          <span>{t('หมายเหตุ : สนามสอบข้ามจังหวัด')}</span>
                        </>
                      )}
                    </div>
                  </li>
                );
              })}
              {Array(5 - pbtPlaces.length)
                .fill()
                .map((__, index) => (
                  <li key={index}>
                    {t('ลำดับที่')} {pbtPlaces.length + index + 1}
                    <span>-</span>
                  </li>
                ))}
            </ul>
            {provinceConfirmed && (
              <>
                <hr className="hr-dashed" />
                <h4 className="color-orange m-0">
                  <b>{t('เลือกสนามสอบใน')} <u>{t('จังหวัด')}{getProvince(pbtPlaces[0].province)}</u> {t('เป็นหลัก')}</b>
                  <small className="block">
                    {t('กรณีสนามสอบที่เลือกไว้ใน')} <u>{t('จังหวัด')}{getProvince(pbtPlaces[0].province)}</u> {t('เต็มและมีการเปิดสนามสอบใหม่ ให้เลือกสนามสอบที่เปิดใหม่นี้ก่อนเลือกสนามสอบในจังหวัดอื่น')}
                  </small>
                </h4>
              </>
            )}
          </li>
        </ul>
      </div>
      <div className='action-step'>
        <a className='btn-step-back _heading cursor-pointer' onClick={onBack}>
          <IconChevronLeft />
          {t('ย้อนกลับ')}
        </a>
        <a className={cn('btn-step-next btn-main cursor-pointer', { loading: submitting })} onClick={submitting ? () => { } : _onSubmit}>
          {t('บันทึกรายการ')}
          <IconChevronRight />
        </a>
      </div>
    </>
  );
};

const IconChevronLeft = () => (
  <svg width='14' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#a)'>
      <path d='M8.87 1.046a.581.581 0 0 0-.824 0L2.504 6.588a.583.583 0 0 0 0 .824l5.542 5.542a.583.583 0 1 0 .825-.825L3.74 7l5.13-5.13a.583.583 0 0 0 0-.824Z' fill='currentColor' />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='currentColor' transform='rotate(-180 7 7)' d='M0 0h14v14H0z' />
      </clipPath>
    </defs>
  </svg>
);

const IconChevronRight = () => (
  <svg width='14' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M5.13 12.954a.581.581 0 0 0 .824 0l5.542-5.542a.583.583 0 0 0 0-.824L5.954 1.046a.583.583 0 1 0-.825.825L10.26 7l-5.13 5.13a.583.583 0 0 0 0 .824Z' fill='#fff' />
  </svg>
);
