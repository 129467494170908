/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { callAPI, isFlSubject } from 'helpers';
import moment from 'moment';

// Components
import { Link, useParams, useNavigate } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ExamSlotInfo from 'components/profile/apply-exams/ExamSlotInfo';
import ExamSiteInfo from 'components/profile/apply-exams/ExamSiteInfo';
import EmailVerification from 'components/otp/EmailVerification';
import MobileVerification from 'components/otp/MobileVerification';

// Hooks
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Data
import { ALEVELINFOS } from 'data/exam-days';
import enabled from 'data/enabled';
import disabilities from 'data/disabilities';

const getProvince = (province) => (['นนทบุรี', 'สมุทรปราการ', 'กรุงเทพมหานคร'].includes(province) ? 'กรุงเทพและปริมณฑล' : province);
const hasDifferentProvince = ({ site, places }) => getProvince(site.province) !== getProvince(_.get(places, '0.province'));

const ALevelDetail = () => {
  const { t } = useTranslation();
  const { day } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profile = useSelector((state) => _.get(state, 'app.user') || {});
  const { date, colorClassName, examSlots } = _.find(ALEVELINFOS, ({ dayIndex }) => `${dayIndex}` === `${day}`);

  const [loading, setLoading] = useState(false);
  const [selections, setSelections] = useState([]);
  const [confirmations, setConfirmations] = useState([]);
  const [siteChangeDisabled, setSiteChangeDisabled] = useState(false);
  const [siteQuotaExceeded, setSiteQuotaExceeded] = useState(false);
  const [flQuotaUsed, setFlQuotaUsed] = useState(0);
  const [expired, setExpired] = useState(false);

  const [examSites, setExamSites] = useState([]);
  const siteMap = _.keyBy(examSites, ({ exam_type, site_code }) => `${exam_type}-${site_code}`);
  const [currentStep, setCurrentStep] = useState();
  const [flow, setFlow] = useState();
  const [subjects, setSubjects] = useState([]);
  const [examType, setExamType] = useState();
  const [pbtPlaces, setPbtPlaces] = useState([]);
  const [cbtPlaces, setCbtPlaces] = useState([]);
  const [provinceConfirmed, setProvinceConfirmed] = useState(false);
  const [specialRequestMap, setSpecialRequestMap] = useState({});

  const [verifyMethod, setVerifyMethod] = useState();
  const [otpVisible, setOtpVisible] = useState(false);

  useEffect(() => {
    const fetchSummary = async () => {
      try {
        setLoading(true);
        const { result } = await callAPI({ url: '/applicants/me/apply-exam-summary/alevel' });
        const _expired = moment(result.timestamp).isAfter(enabled.alevel_apply_exams.expired);
        setSelections(_expired ? result.recent_confirmations : result.selections);
        setExamSites(result.exam_sites);
        setConfirmations(result.recent_confirmations);
        setSiteQuotaExceeded(
          _.get(
            _.maxBy(result.recent_confirmations, ({ site_confirm_id }) => site_confirm_id),
            'site_confirm_id'
          ) >= 3
        );
        setFlQuotaUsed(_.get(_.maxBy(result.recent_confirmations, ({ fl_confirm_id }) => fl_confirm_id), 'fl_confirm_id'));
        setCurrentStep(_.find(result.selections, (sel) => sel.day === day) ? null : 1);
        setExpired(_expired);
        setProvinceConfirmed(_.get(_.find(result.selections, (sel) => sel.day === day), 'province_confirmed'));
        setLoading(false);

        const confirmedSubjectCount = _.sumBy(result.recent_confirmations, ({ subjects = [] }) => subjects.length);
        const totalSubjectCount = _.sumBy(result.selections, ({ subjects = [] }) => subjects.length);
        setSiteChangeDisabled(confirmedSubjectCount > 0 && confirmedSubjectCount !== totalSubjectCount);
      } catch (error) {
        console.log({ error });
        setLoading(false);
      }
    };
    fetchSummary();
  }, [day]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  const onEmailOtpSuccess = useCallback(() => {
    dispatch({ type: 'UPDATE_USER', result: { ...profile, email_is_verified: 1 } });
    navigate(-1);
  }, [dispatch, profile, navigate]);

  const onSmsOtpSuccess = useCallback(() => {
    dispatch({ type: 'UPDATE_USER', result: { ...profile, telephone_is_verified: 1 } });
    navigate(-1);
  }, [dispatch, profile, navigate]);

  const [cancelVisible, setCancelVisible] = useState(false);
  const [canceling, setCanceling] = useState(false);
  const _onSelectionCancel = async () => {
    if (canceling) return;
    try {
      setCanceling(true);
      await callAPI({
        method: 'DELETE',
        url: `/applicants/me/exam-selections/${day}`,
      });
      setCanceling(false);
      navigate('/profile/apply-exams/alevel');
    } catch (error) {
      console.log({ error });
      setCanceling(false);
    }
  };

  const _hasSitesChanged = (confirmation, selection) => {
    if (!confirmation && selection) return false;
    return Array(5)
      .fill()
      .reduce((array, __, i) => array.concat([`place_${i + 1}`, `cbt_place_${i + 1}`]), ['province_confirmed'])
      .some((field) => {
        if (field === 'province_confirmed') return Boolean(confirmation[field]) !== Boolean(selection[field]);
        return confirmation[field] !== selection[field]
      });
  };
  const someSitesChanged = confirmations.some((confirmation) =>
    _hasSitesChanged(
      confirmation,
      _.find(selections, ({ day }) => confirmation.day === day)
    )
  );

  useEffect(() => {
    const classNames = document.getElementById('root').className.split(' ');
    if (loading) {
      document.getElementById('root').className = _.uniq(classNames.concat(['is-loading'])).join(' ');
    } else {
      document.getElementById('root').className = classNames.filter((name) => name !== 'is-loading').join(' ');
    }
  }, [loading]);

  if (loading) {
    return (
      <main className='site-body'>
        <div className='t-loading' />
      </main>
    );
  }

  const currentSelection = _.find(selections, (sel) => sel.day === `${day}`);
  if (currentSelection) {
    if (currentStep) {
      const recentSubjects = currentSelection.subjects.map((_key) => _.find(examSlots, (slot) => slot.subjectKey === _key));
      const specialRequests = currentSelection.special_requests;

      const _pbtPlaces = _.compact(
        Array(5)
          .fill()
          .map((__, i) => _.find(examSites, ({ site_code }) => site_code === currentSelection[`place_${i + 1}`]))
      );
      const _cbtPlaces = _.compact(
        Array(5)
          .fill()
          .map((__, i) => _.find(examSites, ({ site_code }) => site_code === currentSelection[`cbt_place_${i + 1}`]))
      );

      const flowTitle = {
        'add_subject': t('เพิ่มวิชาสอบ'),
        'update_sites': t('แก้ไขสนามสอบ'),
        'update_fl_subject': t('แก้ไขวิชาสอบภาษาต่างประเทศ')
      }[flow];

      if (otpVisible) {
        return (
          <main className='site-body'>
            <div className='t-form'>
              <h1>{flowTitle}</h1>
              {verifyMethod === 'email' ? (
                <EmailVerification studentType='update-fl-subject' email={profile.email} bodyParams={{ subjectKey: _.get(_.find(subjects, ({ subjectCode }) => isFlSubject(subjectCode)), 'subjectKey') }} onSuccess={onEmailOtpSuccess} />
              ) : verifyMethod === 'mobile' ? (
                <MobileVerification studentType='update-fl-subject' mobile={profile.telephone} bodyParams={{ subjectKey: _.get(_.find(subjects, ({ subjectCode }) => isFlSubject(subjectCode)), 'subjectKey') }} onSuccess={onSmsOtpSuccess} />
              ) : null}
            </div>
          </main>
        )
      }

      return (
        <main className='site-body'>
          <div className='t-form'>
            <h1>{flowTitle}</h1>
            {currentStep === 3.5 && (
              <div className='t-box -border -noti'>
                <div className='text-center color-orange'>
                  {t('กรุณาเลือกช่องทางในการส่งรหัส OTP ยืนยันการแก้ไขวิชาสอบภาษาต่างประเทศ')}
                </div>
              </div>
            )}
            <div className={cn('exam-box exam-choose-step', { [colorClassName]: true })}>
              <div className='exam-box-header'>
                <div className='s-flex -between'>
                  <div className='_heading'>
                    <span className='num'>{day - 3}</span>
                    <b>{date}</b>
                  </div>
                </div>
              </div>
              <div className='exam-box-body'>
                {currentStep === 1 ? (
                  <ExamSubjectSelection
                    day={day}
                    colorClassName={colorClassName}
                    examSlots={examSlots}
                    specialRequests={specialRequests}
                    selectedSubjects={recentSubjects}
                    onNext={(subjects, specialRequestMap) => {
                      setSubjects(subjects);
                      setSpecialRequestMap(specialRequestMap);
                      setCurrentStep(3);
                    }}
                  />
                ) : currentStep === 1.5 ? (
                  <ExamFLSubjectSelection
                    day='6'
                    colorClassName='-monday'
                    examSlots={examSlots}
                    specialRequest={_.get(specialRequests, 'Foreign')}
                    flSubject={_.find(recentSubjects, ({ subjectCode }) => isFlSubject(subjectCode))}
                    onNext={(_subject) => {
                      setSubjects((recentSubjects.filter(({ subjectCode }) => !isFlSubject(subjectCode)).concat(_subject)));
                      const day6Confirmation = _.find(confirmations, ({ day }) => day === '6');
                      if (!day6Confirmation) {
                        setCurrentStep(3);
                      } else {
                        setCurrentStep(3.5);
                      }
                    }}
                  />
                ) : currentStep === 2 ? (
                  <ExamSiteSelection
                    day={day}
                    examType={examType || currentSelection.exam_type}
                    examSites={examSites}
                    selectedPbtPlaces={_pbtPlaces.length ? _pbtPlaces : pbtPlaces}
                    selectedCbtPlaces={_cbtPlaces.length ? _cbtPlaces : cbtPlaces}
                    provinceConfirmed={provinceConfirmed}
                    onBack={() => setCurrentStep()}
                    onNext={(examType, pbtPlaces, cbtPlaces = [], provinceConfirmed) => {
                      setExamType(examType);
                      setPbtPlaces(pbtPlaces);
                      setCbtPlaces(cbtPlaces);
                      setProvinceConfirmed(provinceConfirmed);
                      setCurrentStep(3);
                    }}
                  />
                ) : currentStep === 3 ? (
                  <ExamSelectionSummary
                    day={day}
                    colorClassName={colorClassName}
                    examType={examType}
                    recentSubjects={recentSubjects}
                    subjects={subjects}
                    pbtPlaces={pbtPlaces.length ? pbtPlaces : _pbtPlaces}
                    cbtPlaces={examType === 'pbt' ? [] : cbtPlaces.length ? cbtPlaces : _cbtPlaces}
                    provinceConfirmed={provinceConfirmed}
                    specialRequestMap={specialRequestMap}
                    onBack={() => setCurrentStep(flow === 'add_subject' ? 1 : flow === 'update_sites' ? 2 : null)} />
                ) : currentStep === 3.5 ? (
                  <ExamFlSubjectConfirmation
                    day='6'
                    colorClassName='-monday'
                    examType='pbt'
                    flSubject={_.find(subjects, (({ subjectCode }) => isFlSubject(subjectCode)))}
                    pbtPlaces={pbtPlaces.length ? pbtPlaces : _pbtPlaces}
                    specialRequest={_.get(currentSelection, 'special_requests.Foreign')}
                    onBack={() => setCurrentStep(1.5)} />
                ) : null}
              </div>
            </div>
            {currentStep === 3.5 && (
              <>
                <div className="i-hearts">
                  <i className={cn('i-heart', { '-blink': flQuotaUsed === 2, '-used': flQuotaUsed > 2 })} />
                  <i className={cn('i-heart', { '-blink': flQuotaUsed === 1, '-used': flQuotaUsed > 1 })} />
                  <i className={cn('i-heart', { '-blink': flQuotaUsed === 0, '-used': flQuotaUsed > 0 })} />
                  <small>
                    {t('ตัดสินใจครั้งที่')} {1 + flQuotaUsed}/3
                  </small>
                </div>
                <MethodSelector
                  verifyMethod={verifyMethod}
                  setVerifyMethod={setVerifyMethod}
                  onBack={() => {
                    setCurrentStep(1.5)
                    setOtpVisible(false)
                  }}
                  onClick={() => setOtpVisible(true)} />
              </>
            )}
          </div>
        </main>
      );
    }

    return (
      <main className='site-body'>
        <div className='t-form'>
          <h1>{t('รายละเอียดการสมัครสอบ')}</h1>
          <div className={cn('exam-box exam-choose-step', { [colorClassName]: true })}>
            <div className='exam-box-header'>
              <div className='s-flex -between'>
                <div className='_heading'>
                  <span className='num'>{day - 3}</span>
                  <b>{date}</b>
                </div>
              </div>
            </div>
            <div className='exam-box-body'>
              {ALEVELINFOS.map(({ dayIndex, colorClassName, examSlots }, index) => {
                const selection = _.find(selections, (sel) => sel.day === `${dayIndex}` && sel.day === day);
                const confirmation = _.find(confirmations, (sel) => sel.day === `${dayIndex}` && sel.day === day);
                if (!selection) return null;
                const normalSlots = examSlots
                  .filter(({ subjectKey }) => selection.subjects.includes(subjectKey))
                  .filter(({ subjectCode }) => !isFlSubject(subjectCode));

                return (
                  <>
                    <div className={cn('exam-box', { [colorClassName]: true })}>
                      <ul className='exam-box-list'>
                        {normalSlots.length ? (
                          <>
                            {normalSlots.map((slot, slotIndex, all) => {
                              const last = (slotIndex === all.length - 1);
                              const maxedOut = examSlots.length === (selection.subjects || []).length;
                              const addable = day === '6'
                                ? (selection.subjects || []).length < 3
                                : !maxedOut;

                              return (
                                <ExamSlotInfo
                                  key={slotIndex}
                                  examType={selection.exam_type}
                                  specialRequests={selection.special_requests}
                                  confirmations={confirmation ? [confirmation] : []}
                                  slot={slot}
                                  footer={
                                    (expired || !last || maxedOut) ? null : someSitesChanged ? (
                                      <li>
                                        <h4 className='text-center color-orange m-0 _heading'>
                                          <b>
                                            {t('ไม่สามารถเพิ่มวิชาสอบได้')}
                                            <br />
                                            {t('กรุณายืนยันการแก้ไขสนามสอบก่อน')}
                                          </b>
                                        </h4>
                                      </li>
                                    ) : addable ? (
                                      <li>
                                        <div className='text-center'>
                                          <a
                                            onClick={() => {
                                              setCurrentStep(1);
                                              setFlow('add_subject');
                                            }}
                                            className='btn-exam-underline color-blue cursor-pointer'
                                          >
                                            {t('เพิ่มวิชาสอบ')}
                                          </a>
                                          {Boolean(confirmation) && <div className='color-gray _heading'>{t('โดยชำระค่าสมัครเพิ่มเติม')}</div>}
                                        </div>
                                      </li>
                                    ) : null
                                  }
                                />
                              );
                            })}
                          </>
                        ) : (
                          <>
                            <li style={{ marginBottom: 0 }}>
                              <div className="text-center">{t('สมัครสอบวิชาอื่น')}</div>
                            </li>
                            {expired ? null : someSitesChanged ? (
                              <li>
                                <h4 className='text-center color-orange m-0 _heading'>
                                  <b>
                                    {t('ไม่สามารถเพิ่มวิชาสอบได้')}
                                    <br />
                                    {t('กรุณายืนยันการแก้ไขสนามสอบก่อน')}
                                  </b>
                                </h4>
                              </li>
                            ) : (
                              <li>
                                <div className='text-center'>
                                  <a
                                    onClick={() => {
                                      setCurrentStep(1);
                                      setFlow('add_subject');
                                    }}
                                    className='btn-exam-underline color-blue cursor-pointer'
                                  >
                                    {t('เพิ่มวิชาสอบ')}
                                  </a>
                                  {Boolean(confirmation) && <div className='color-gray _heading'>{t('โดยชำระค่าสมัครเพิ่มเติม')}</div>}
                                </div>
                              </li>
                            )}
                          </>
                        )}
                      </ul>
                    </div>
                    {Boolean(examSlots
                      .filter(({ subjectKey }) => selection.subjects.includes(subjectKey))
                      .filter(({ subjectCode }) => isFlSubject(subjectCode))
                      .length) && (
                        <div className={cn('exam-box', { [colorClassName]: true })}>
                          <ul className='exam-box-list'>
                            {examSlots
                              .filter(({ subjectKey }) => selection.subjects.includes(subjectKey))
                              .filter(({ subjectCode }) => isFlSubject(subjectCode))
                              .map((slot, slotIndex, all) => {
                                const last = (slotIndex === all.length - 1);
                                const quotaExceeded = flQuotaUsed >= 3;
                                return (
                                  <ExamSlotInfo
                                    key={slotIndex}
                                    fl
                                    examType={selection.exam_type}
                                    specialRequests={selection.special_requests}
                                    confirmations={confirmation ? [confirmation] : []}
                                    slot={slot}
                                    footer={
                                      (expired || !last) ? null : quotaExceeded ? (
                                        <li>
                                          <h4 className='text-center color-orange m-0 _heading'>
                                            <b>
                                              {t('ผู้สมัครแก้ไขวิชาสอบภาษาต่างประเทศครบ 3 ครั้งแล้ว')}
                                              <br />
                                              {t('ไม่สามารถเปลี่ยนแปลงได้อีก')}
                                            </b>
                                          </h4>
                                        </li>
                                      ) : someSitesChanged ? (
                                        <li>
                                          <h4 className='text-center color-orange m-0 _heading'>
                                            <b>
                                              {t('ไม่สามารถแก้ไขวิชาสอบภาษาต่างประเทศได้')}
                                              <br />
                                              {t('กรุณายืนยันการแก้ไขสนามสอบก่อน')}
                                            </b>
                                          </h4>
                                        </li>
                                      ) : (_.get(confirmation, 'subjects') || []).includes(slot.subjectCode) ? (
                                        <li>
                                          <h4 className='text-center color-orange m-0 _heading'>
                                            <b>
                                              {t('ไม่สามารถแก้ไขวิชาสอบภาษาต่างประเทศได้')}
                                              <br />
                                              {t('กรุณายืนยันการสมัครสอบก่อน')}
                                            </b>
                                          </h4>
                                        </li>
                                      ) : (
                                        <li>
                                          <div className='text-center'>
                                            <a
                                              onClick={() => {
                                                setCurrentStep(1.5);
                                                setFlow('update_fl_subject');
                                              }}
                                              className='btn-exam-underline color-blue cursor-pointer'
                                            >
                                              {t('แก้ไขวิชาสอบภาษาต่างประเทศ')}
                                            </a>
                                            {Boolean(confirmation) && <div className='color-gray _heading'>{t('แก้ไขได้ทั้งหมด 3 ครั้ง ไม่มีค่าใช้จ่าย')}</div>}
                                          </div>
                                        </li>
                                      )
                                    }
                                  />
                                );
                              })}
                          </ul>
                        </div>
                      )}
                    <div className={cn('exam-box', { [colorClassName]: true })}>
                      <ul className='exam-box-list'>
                        <ExamSiteInfo
                          examType={_.get(_.find(selections, (sel) => sel.day === `${dayIndex}` && sel.day === day), 'exam_type')}
                          data={_.find(selections, (sel) => sel.day === `${dayIndex}` && sel.day === day)}
                          siteMap={siteMap} />
                        {expired ? null : siteChangeDisabled ? (
                          <li>
                            <h4 className='text-center color-orange m-0 _heading'>
                              <b>
                                {t('ไม่สามารถแก้ไขสนามสอบได้')}
                                <br />
                                {t('กรุณายืนยันการสมัครก่อน')}
                              </b>
                            </h4>
                          </li>
                        ) : siteQuotaExceeded ? (
                          <li>
                            <h4 className='text-center color-orange m-0'>
                              <b>
                                {t('ผู้สมัครแก้ไขสนามสอบครบ 3 ครั้งแล้ว')}
                                <br />
                                {t('ไม่สามารถเปลี่ยนแปลงได้อีก')}
                              </b>
                            </h4>
                          </li>
                        ) : (
                          <li>
                            <div className='text-center'>
                              <a
                                onClick={() => {
                                  setCurrentStep(2);
                                  setFlow('update_sites');
                                }}
                                className='btn-exam-underline color-blue cursor-pointer'
                              >
                                {t('แก้ไขสนามสอบ')}
                              </a>
                              {Boolean(confirmation) && <div className='color-gray _heading'>{t('แก้ไขได้ทั้งหมด 3 ครั้ง ไม่มีค่าใช้จ่าย')}</div>}
                            </div>
                          </li>
                        )}
                      </ul>
                    </div>
                    {(_.get(confirmation, 'subjects') || []).length !== selection.subjects.length && (
                      <div className={cn('r-more', { active: cancelVisible })}>
                        <h4 className='text-center color-orange' onClick={() => setCancelVisible((_visible) => !_visible)}>
                          <b>{t('ยกเลิกการแก้ไข?')}</b> <i className='i-more'></i>
                        </h4>
                        <div className='more'>
                          <div className='text-center'>
                            {t('หากต้องการกลับไปที่การสมัครครั้งที่ผ่านมา')} <br />
                            {t('ให้คลิกเลือกยกเลิกการแก้ไข')}
                          </div>
                          <div className='_space' />
                          <div className='action'>
                            <a onClick={_onSelectionCancel} className={cn('btn-eaxm-cancel cursor-pointer _heading', { loading: canceling, disabled: canceling })}>
                              {t('ยกเลิกการแก้ไข')}
                            </a>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          </div>
          <div className='_space' />
          <div className='text-center'>
            <Link to='/profile/apply-exams/alevel' className='color-gray _heading'>
              <i className='i-arrow-left'></i> {t('ย้อนกลับ')}
            </Link>
          </div>
        </div>
      </main>
    );
  }

  if (expired) return (
    <main className='site-body'>
      <div className='t-form'>
        <h1>{t('หมดเวลาสมัครสอบแล้ว')}</h1>
      </div>
    </main>
  );

  return (
    <main className='site-body'>
      <div className='t-form'>
        <h1>{t('เลือกวิชาสอบและสนามสอบ')}</h1>
        <div className={cn('exam-box exam-choose-step', { [colorClassName]: true })}>
          <div className='exam-box-header'>
            <div className='s-flex -between'>
              <div className='_heading'>
                <span className='num'>{day - 3}</span>
                <b>{date}</b>
              </div>
            </div>
          </div>
          <div className='exam-box-body'>
            <ul className='t-step'>
              <li className={cn({ active: currentStep === 1 })}>
                <span>{t('เลือกวิชาสอบ')}</span>
              </li>
              <li className={cn({ active: currentStep === 2 })}>
                <span>{t('เลือกสนามสอบ')}</span>
              </li>
              <li className={cn({ active: currentStep === 3 })}>
                <span>{t('ตรวจสอบข้อมูล')}</span>
              </li>
            </ul>
            {currentStep === 1 && (
              <ExamSubjectSelection
                day={day}
                colorClassName={colorClassName}
                examSlots={examSlots}
                onNext={(subjects, specialRequestMap) => {
                  setSubjects(subjects);
                  setSpecialRequestMap(specialRequestMap);
                  setCurrentStep(2);
                }}
              />
            )}
            {currentStep === 2 && (
              <ExamSiteSelection
                day={day}
                examType={examType}
                examSites={examSites}
                selectedPbtPlaces={pbtPlaces}
                selectedCbtPlaces={cbtPlaces}
                provinceConfirmed={provinceConfirmed}
                onBack={() => setCurrentStep(1)}
                onNext={(examType, pbtPlaces, cbtPlaces = [], provinceConfirmed) => {
                  setExamType(examType);
                  setPbtPlaces(pbtPlaces);
                  setCbtPlaces(cbtPlaces);
                  setProvinceConfirmed(provinceConfirmed);
                  setCurrentStep(3);
                }}
              />
            )}
            {currentStep === 3 && (
              <ExamSelectionSummary
                day={day}
                colorClassName={colorClassName}
                examType={examType}
                subjects={subjects}
                pbtPlaces={pbtPlaces}
                cbtPlaces={cbtPlaces}
                provinceConfirmed={provinceConfirmed}
                specialRequestMap={specialRequestMap}
                onBack={() => setCurrentStep(2)} />
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default ALevelDetail;

const ExamFLSubjectSelection = ({ examSlots, flSubject, specialRequest, onNext }) => {
  const { t } = useTranslation();
  const [subjectKey, setSubjectKey] = useState(flSubject.subjectKey);
  const disabled = subjectKey === flSubject.subjectKey;

  return (
    <>
      <div className='choose-subject'>
        <span className='color-blue _heading'>{t('วิชาสอบภาษาต่างประเทศ')}</span>
        {specialRequest && (
          <>
            <h4 className="color-orange m-0 flex items-center gap-1">
              <i className="i-disability"></i>
              <small><b>{specialRequest}</b></small>
            </h4>
          </>
        )}
        <br />
        <div className='xm-box -radio'>
          <div className='num'>
            <b>1</b>
          </div>
          {examSlots
            .filter(subject => isFlSubject(subject.subjectCode))
            .map((subject, index, all) => {
              return (
                <>
                  <label>
                    <input type='radio' checked={subject.subjectKey === subjectKey} onChange={() => setSubjectKey(subject.subjectKey)} />
                    <ul>
                      <li>
                        {t('รหัสวิชา')} <b>{subject.subjectCode}</b>
                      </li>
                      <li>
                        {t('ชื่อวิชา')}{' '}
                        <b>
                          {subject.subjectKey} {subject.subjectName}
                        </b>
                      </li>
                      <li>
                        {t('เวลา')} <b>{subject.time}</b>
                      </li>
                    </ul>
                    {Boolean((subject.subSubjects || []).length) && (
                      <>
                        <div className='color-blue remark'>* {t('สำหรับผู้ต้องการสอบวิชาเหล่านี้')}</div>
                        <hr className='hr-dashed' />
                        <ul className='list-style-disc'>
                          {subject.subSubjects.map((sub) => (
                            <li key={sub.subjectCode}>
                              {t('รหัสวิชา')} <b>{sub.subjectCode}</b>
                              <br />
                              {t('ชื่อวิชา')}{' '}
                              <b>
                                {sub.subjectKey} {sub.subjectName}
                              </b>
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </label>
                </>
              );
            })}
        </div>
      </div>
      <div className='action-step'>
        <Link to='/profile/apply-exams/alevel' className='btn-step-back _heading'>
          <IconChevronLeft />
          {t('ย้อนกลับ')}
        </Link>
        <a
          className={cn('btn-step-next btn-main', {
            'cursor-pointer': !disabled,
            disabled
          })}
          onClick={disabled ? () => { } : () => onNext(_.find(examSlots, slot => slot.subjectKey === subjectKey))}
        >
          {t('ไปหน้าถัดไป')}
          <IconChevronRight />
        </a>
      </div>
    </>
  )
}

const ExamSubjectSelection = ({ day, examSlots, colorClassName, selectedSubjects = [], specialRequests = {}, onNext }) => {
  const { t } = useTranslation();
  const profile = useSelector((state) => _.get(state, 'app.user') || {});

  const [subjects, setSubjects] = useState(selectedSubjects);
  const [specialRequestMap, setSpecialRequestMap] = useState({});
  const onCheck = (subject) => {
    const { subjectCode, subjectKey } = subject;
    setSubjects((_initialSubjects) => {
      const subjectCodes = _initialSubjects.map(({ subjectCode }) => subjectCode);
      if (subjectCodes.includes(subjectCode)) return _initialSubjects.filter((_subject) => _subject.subjectCode !== subjectCode);
      if (isFlSubject(subjectCode)) return _initialSubjects.filter((_subject) => !isFlSubject(_subject.subjectCode)).concat([subject]);
      return _initialSubjects.concat([subject]);
    });

    if (profile.special_request) {
      const _key = isFlSubject(subjectCode) ? 'Foreign' : subjectKey;
      if (_key === 'Foreign') {
        setSpecialRequestMap(_map => ({
          ..._map,
          Foreign: subjects.map(({ subjectCode }) => subjectCode).includes(subjectCode)
            ? null
            : profile.special_request
        }));
      } else {
        setSpecialRequestMap(_map => ({
          ..._map,
          [_key]: _map[_key] ? null : profile.special_request
        }));
      }
    }
  }
  const disabled = !(subjects.length - selectedSubjects.length);

  const flSubjectSelected = day === '6' && subjects.some(({ subjectCode }) => isFlSubject(subjectCode));
  return (
    <>
      {Boolean(!selectedSubjects.length) && <span className='color-blue _heading'>{t('เลือกวิชาสอบและสนามสอบ')}</span>}
      <div className='choose-subject'>
        {/* วิชาสอบที่สมัครไปแล้ว */}
        {examSlots
          .filter((subject) => selectedSubjects.map(({ subjectKey }) => subjectKey).includes(subject.subjectKey))
          .map((subject, index) => (
            <div index={index} className={cn('exam-box', { [colorClassName]: true })}>
              <ul className='exam-box-list'>
                <ExamSlotInfo
                  slot={subject}
                  specialRequests={specialRequests} />
              </ul>
            </div>
          ))}
        {/* วิชาสอบที่ยังไม่สมัคร และไม่เป็นวิชาสอบภาษาต่างประเทศ */}
        {examSlots
          .filter((subject) => !selectedSubjects.map(({ subjectKey }) => subjectKey).includes(subject.subjectKey))
          .filter((subject) => (day === '6' ? !isFlSubject(subject.subjectCode) : true))
          .map((subject, index) => {
            const { time, subjectKey, subjectName, subjectCode, subSubjects } = subject;
            const checked = subjects.some((_subject) => _subject.subjectKey === subjectKey);
            const specialRequest = specialRequestMap[subjectKey];
            const specialRequestIndex = _.findIndex(disabilities, text => text === specialRequest);

            return (
              <div key={index} className='xm-box'>
                <div className='num'>
                  <b>{index + 1}</b>
                </div>
                <label>
                  <input type='checkbox' checked={checked} onChange={() => onCheck(subject)} />
                  <ul>
                    <li>
                      {t('รหัสวิชา')} <b>{subjectCode}</b>
                    </li>
                    <li>
                      {t('ชื่อวิชา')}{' '}
                      <b>
                        {subjectKey} {subjectName}
                      </b>
                    </li>
                    <li>
                      {t('เวลา')} <b>{time}</b>
                    </li>
                  </ul>
                  {Boolean((subSubjects || []).length) && (
                    <>
                      <div className='color-blue remark'>* {t('สำหรับผู้ต้องการสอบวิชาเหล่านี้')}</div>
                      <hr className='hr-dashed' />
                      <ul className='list-style-disc'>
                        {subSubjects.map((sub) => (
                          <li key={sub.subjectCode}>
                            {t('รหัสวิชา')} <b>{sub.subjectCode}</b>
                            <br />
                            {t('ชื่อวิชา')}{' '}
                            <b>
                              {sub.subjectKey} {sub.subjectName}
                            </b>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </label>
                {checked && Boolean(profile.special_request) && (
                  <>
                    <hr className="hr-dashed" />
                    <h4 className="color-orange mb-1 flex items-center gap-1">
                      <i className="i-disability"></i>
                      <span>{t('ความช่วยเหลือพิเศษ')}</span>
                    </h4>
                    <select
                      required
                      value={specialRequestIndex}
                      onChange={(e) => setSpecialRequestMap(_map => ({ ..._map, [subjectKey]: disabilities[e.target.value] }))}>
                      <option>ไม่ต้องการความช่วยเหลือพิเศษ</option>
                      {disabilities.map((text, index) => (
                        <option key={index} value={index}>
                          {text}
                        </option>
                      ))}
                    </select>
                  </>
                )}
              </div>
            );
          })}
        {/* วิชาสอบที่สมัครไปแล้ว และเป็นวิชาสอบภาษาต่างประเทศ */}
        {day === '6' && !selectedSubjects.some(({ subjectCode }) => isFlSubject(subjectCode)) && (
          <>
            <span className='color-blue _heading'>{t('วิชาสอบภาษาต่างประเทศ')}</span>
            <div className='xm-box -radio'>
              <div className='num'>
                <b>1</b>
              </div>
              {examSlots
                .filter((subject) => !selectedSubjects.map(({ subjectKey }) => subjectKey).includes(subject.subjectKey))
                .filter((subject) => isFlSubject(subject.subjectCode))
                .map((subject, index, all) => {
                  const { time, subjectKey, subjectName, subjectCode, subSubjects } = subject;
                  const last = index === all.length - 1;
                  return (
                    <label style={last && flSubjectSelected && Boolean(profile.special_request) ? { borderBottomStyle: 'dashed' } : null}>
                      <input type='radio'
                        checked={subjects.some((_subject) => _subject.subjectKey === subjectKey)}
                        onClick={() => onCheck(subject)} />
                      <ul>
                        <li>
                          {t('รหัสวิชา')} <b>{subjectCode}</b>
                        </li>
                        <li>
                          {t('ชื่อวิชา')}{' '}
                          <b>
                            {subjectKey} {subjectName}
                          </b>
                        </li>
                        <li>
                          {t('เวลา')} <b>{time}</b>
                        </li>
                      </ul>
                      {Boolean((subSubjects || []).length) && (
                        <>
                          <div className='color-blue remark'>* {t('สำหรับผู้ต้องการสอบวิชาเหล่านี้')}</div>
                          <hr className='hr-dashed' />
                          <ul className='list-style-disc'>
                            {subSubjects.map((sub) => (
                              <li key={sub.subjectCode}>
                                {t('รหัสวิชา')} <b>{sub.subjectCode}</b>
                                <br />
                                {t('ชื่อวิชา')}{' '}
                                <b>
                                  {sub.subjectKey} {sub.subjectName}
                                </b>
                              </li>
                            ))}
                          </ul>
                        </>
                      )}
                    </label>
                  );
                })}
              {flSubjectSelected && Boolean(profile.special_request) && (
                <div style={{ padding: '0px 16px 16px 28px' }}>
                  <h4 className="color-orange mb-1 flex items-center gap-1">
                    <i className="i-disability"></i>
                    <span>{t('ความช่วยเหลือพิเศษ')}</span>
                  </h4>
                  <select
                    required
                    value={_.findIndex(disabilities, text => text === specialRequestMap['Foreign'])}
                    onChange={(e) => setSpecialRequestMap(_map => ({ ..._map, Foreign: disabilities[e.target.value] }))}>
                    <option>ไม่ต้องการความช่วยเหลือพิเศษ</option>
                    {disabilities.map((text, index) => (
                      <option key={index} value={index}>
                        {text}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <div className='action-step'>
        <Link to='/profile/apply-exams/alevel' className='btn-step-back _heading'>
          <IconChevronLeft />
          {t('ย้อนกลับ')}
        </Link>
        <a
          className={cn('btn-step-next btn-main', {
            'cursor-pointer': !disabled,
            disabled,
          })}
          onClick={disabled ? () => { } : () => onNext(subjects, specialRequestMap)}
        >
          {t('ไปหน้าถัดไป')}
          <IconChevronRight />
        </a>
      </div>
    </>
  );
};

const SiteSelector = ({ day, profile, examType, examSites, droppableId, places, onPlacesChange, checked, onCheckedChange, provinceConfirmed, onProvinceConfirm }) => {
  const dragDropRef = useRef();
  const { t } = useTranslation();

  const [height, setHeight] = useState(0);

  const _onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) return;
    onPlacesChange((places) => {
      const [removed] = places.splice(result.source.index, 1);
      places.splice(result.destination.index, 0, removed);
      return places;
    });
  };

  const [keyword, setKeyword] = useState('');
  const _onSelect = (site) => {
    onPlacesChange((_places) => (_places.some((_place) => _place.site_code === site.site_code) ? _places.filter((_place) => _place.site_code !== site.site_code) : _places.concat([site])));
  };

  const filteredSites = examSites.filter((site) => {
    const baseLogic = (!keyword || site.school_name.includes(keyword) || site.province.includes(keyword)) && site.exam_type === examType;
    switch (examType) {
      case 'cbt':
        if (profile.special_request) return false;
        return baseLogic && Boolean(_.get(site, `info_by_days.day${day}.cbt_seats`));
      case 'pbt':
        // if (profile.special_request) return baseLogic && Boolean(_.get(site, `info_by_days.day${day}.special_seats`));
        return baseLogic && Boolean(_.get(site, `info_by_days.day${day}.seats`));
      default:
        return baseLogic;
    }
  });

  return (
    <>
      <ul ref={dragDropRef} className='r-choose -exam -drag' style={height ? { height } : null}>
        <DragDropContext
          onBeforeDragStart={() => setHeight((dragDropRef.current || {}).offsetHeight || 0)}
          onDragEnd={(result) => {
            _onDragEnd(result);
            setHeight(0);
          }}
        >
          <Droppable droppableId={droppableId}>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {(places || []).map((site, index) => {
                  return (
                    <Draggable key={site.site_code} draggableId={site.site_code} index={index}>
                      {(provided) => {
                        const warning = hasDifferentProvince({ site, places });
                        return (
                          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={{ useSelect: 'none', ...provided.draggableProps.style }}>
                            <li>
                              <i className='i-drag -sky' />
                              <label className={cn({ '-error': warning })}>
                                <div className='num'>
                                  <b>{index + 1}</b>
                                </div>
                                <div className='icon'>
                                  <i
                                    className={cn({
                                      'i-computer': site.exam_type === 'cbt',
                                      'i-paper': site.exam_type === 'pbt',
                                    })}
                                  />
                                </div>
                                <strong>{site.school_name}</strong>
                                <span>{site.province}</span>
                                {warning && (
                                  <>
                                    <hr className='hr-dashed' />
                                    <span className='remark'>
                                      <b>{t('หมายเหตุ : สนามสอบข้ามจังหวัด')}</b>
                                    </span>
                                  </>
                                )}
                              </label>
                              <i className='i-exam-del cursor-pointer' onClick={() => onPlacesChange((_places) => _places.filter((_place) => _place.site_code !== site.site_code))} />
                            </li>
                            {Boolean(places.length) && !index && (
                              <label id="province-confirm">
                                <input type="checkbox" checked={provinceConfirmed} onChange={() => onProvinceConfirm(_confirmed => !_confirmed)} />
                                <h4 className="color-orange">
                                  <b>{t('เลือกสนามสอบใน')} <u>{t('จังหวัด')}{getProvince(places[0].province)}</u> {t('เป็นหลัก')}</b>
                                  <small className="block">
                                    {t('กรณีสนามสอบที่เลือกไว้ใน')} <u>{t('จังหวัด')}{getProvince(places[0].province)}</u> {t('เต็มและมีการเปิดสนามสอบใหม่ ให้เลือกสนามสอบที่เปิดใหม่นี้ก่อนเลือกสนามสอบในจังหวัดอื่น')}
                                  </small>
                                </h4>
                              </label>
                            )}
                          </div>
                        );
                      }}
                    </Draggable>
                  );
                })}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </ul>
      {Array(5 - places.length)
        .fill()
        .map((__, index) => (
          <div key={index} className='t-row'>
            <div className='col -full'>
              <input type='text' placeholder={`${t('ลำดับที่')} ${places.length + index + 1} * -`} disabled />
            </div>
          </div>
        ))}
      <p>{t('ค้นหาสนามสอบ โดยเลือกเรียงตามลำดับที่ผู้สมัครต้องการเข้าสอบ จากมากที่สุดไปน้อยที่สุด')}</p>
      <div className='t-search'>
        <div className='input-group'>
          <input type='text' placeholder={t('ค้นหาสนามสอบ')} onChange={(e) => setKeyword(e.target.value.trim())} value={keyword} />
          <i className='i-search'></i>
        </div>
      </div>
      {Boolean(keyword) && (
        <div className='t-result'>
          <h2>
            <strong>{t('ผลการค้นหา')}</strong>
            <span className='sep'>•</span>
            <b>
              {(filteredSites || []).length} {t('สนามสอบ')}
            </b>
            <i className='i-close' onClick={() => setKeyword('')} />
          </h2>
          <ul className='eaxm-field-list' style={{ overflowY: 'auto', maxHeight: '450px' }}>
            {filteredSites.map((site) => {
              const active = places.some((place) => place.site_code === site.site_code);
              const selectable = places.length < 5 || active;
              return (
                <li key={site.site_code}>
                  <a className={cn({ 'cursor-pointer': selectable })} onClick={selectable ? () => _onSelect(site) : () => { }}>
                    <div className='icon'>
                      <i
                        className={cn({
                          'i-computer i-20': site.exam_type === 'cbt',
                          'i-paper i-20': site.exam_type === 'pbt',
                        })}
                      />
                    </div>
                    <div className='field-info'>
                      <h4>
                        {site.school_name}
                        <br />
                        <small>{site.province}</small>
                      </h4>
                    </div>
                    {selectable && (
                      <div className='choose'>
                        <i className={cn('i-checked', { active })} />
                      </div>
                    )}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      )}
      {places.some((site) => getProvince(site.province) !== getProvince(places[0].province)) && (
        <>
          <div className='_space' />
          <label className='exam-comfirm-cross'>
            <input type='checkbox' checked={checked} onChange={onCheckedChange} />
            <span className='color-orange'>{t('ยืนยันเลือกสนามสอบข้ามจังหวัด')}</span>
          </label>
        </>
      )}
    </>
  );
};

const isDisabled = (places, checked) => {
  return !places.length || (!checked && places.some((site) => hasDifferentProvince({ site, places })));
};
const ExamSiteSelection = ({ day, examType: _examType, examSites, selectedPbtPlaces = [], selectedCbtPlaces = [], provinceConfirmed: _confirmed, onBack, onNext }) => {
  const { t } = useTranslation();
  const profile = useSelector((state) => _.get(state, 'app.user') || {});

  const [examType] = useState('pbt');
  const [pbtPlaces, setPbtPlaces] = useState(selectedPbtPlaces);
  const [pbtChecked, setPbtChecked] = useState(false);
  const [cbtPlaces, setCbtPlaces] = useState(selectedCbtPlaces);
  const [cbtChecked, setCbtChecked] = useState(false);
  const [provinceConfirmed, setProvinceConfirmed] = useState(_confirmed);

  const pbtSites = examSites.filter((site) => site.exam_type === 'pbt' && Boolean(_.get(site, `info_by_days.day${day}.seats`)));
  // const pbtSites = examSites.filter((site) => site.exam_type === 'pbt' && Boolean(_.get(site, `info_by_days.day${day}.${profile.special_request ? 'special_seats' : 'seats'}`)));
  const firstSiteProvinceCount = pbtSites.filter(({ province }) => getProvince(province) === getProvince(_.get(pbtPlaces, '0.province'))).length;
  const minSitesSelected = pbtPlaces.length < 5 && pbtPlaces.filter((place) => _.get(place, 'province') === _.get(pbtPlaces, '0.province')).length < firstSiteProvinceCount;
  const nextDisabled = isDisabled(pbtPlaces, pbtChecked) || (examType === 'cbt' && isDisabled(cbtPlaces, cbtChecked));

  const _onNext = () => {
    if (minSitesSelected) {
      if (firstSiteProvinceCount >= 5) {
        alert('กรุณาเลือกสนามสอบให้ครบทั้ง 5 ลำดับ');
      } else {
        alert(`กรุณาเลือกทุกสนามสอบในจังหวัด${_.get(pbtPlaces, '0.province')}`);
      }
    } else {
      onNext(examType, pbtPlaces, examType === 'pbt' ? [] : cbtPlaces, provinceConfirmed);
    }
  };

  return (
    <>
      <h4 className='title'>{t('เลือกประเภทข้อสอบ')}</h4>
      <div className='exam-type'>
        <div className='inline-group'>
          <i className='i-paper'></i>
          <label htmlFor='type1'>{t('กระดาษ')}</label>
          <input type='radio' name='type' id='type1' checked={examType === 'pbt'} />
        </div>
      </div>
      <hr className='hr-dashed' />
      <h4 className='title'>{t('เลือกสนามสอบ')}</h4>
      {examType === 'pbt' ? (
        <div>
          <p>{t('กรุณาเลือกสนามสอบให้ครบ 5 ลำดับ ยกเว้นกรณีที่จังหวัดในลำดับแรกมีสนามสอบน้อยกว่า 5 สนามสอบ ให้เลือกทุกสนามสอบในจังหวัดนั้นแทน')}</p>
          <SiteSelector
            droppableId='droppable'
            day={day}
            examType='pbt'
            profile={profile}
            examSites={examSites}
            places={pbtPlaces}
            onPlacesChange={setPbtPlaces}
            checked={pbtChecked}
            onCheckedChange={() => setPbtChecked((_checked) => !_checked)}
            onProvinceConfirm={setProvinceConfirmed}
            provinceConfirmed={provinceConfirmed} />
        </div>
      ) : (
        <>
          <hr className='hr-dashed' />
          <div className='title'>1. {t('สนามสอบคอมพิวเตอร์')}</div>
          <SiteSelector
            droppableId='cbt_droppable'
            day={day}
            examType='cbt'
            profile={profile}
            examSites={examSites}
            places={cbtPlaces}
            onPlacesChange={setCbtPlaces}
            checked={cbtChecked}
            onCheckedChange={() => setCbtChecked((_checked) => !_checked)}
            onProvinceConfirm={setProvinceConfirmed}
            provinceConfirmed={provinceConfirmed} />
          <hr className='hr-dashed' />
          <div className='title'>2. {t('สนามสอบกระดาษ')}</div>
          <p className='subtitle color-orange _heading'>{t('ต้องเลือกเผื่อกรณีสนามสอบคอมพิวเตอร์เต็ม')}</p>
          <p>{t('กรุณาเลือกสนามสอบให้ครบ 5 ลำดับ ยกเว้นกรณีที่จังหวัดในลำดับแรกมีสนามสอบน้อยกว่า 5 สนามสอบ ให้เลือกทุกสนามสอบในจังหวัดนั้นแทน')}</p>
          <SiteSelector
            droppableId='pbt_droppable'
            day={day}
            examType='pbt'
            profile={profile}
            examSites={examSites}
            places={pbtPlaces}
            onPlacesChange={setPbtPlaces}
            checked={pbtChecked}
            onCheckedChange={() => setPbtChecked((_checked) => !_checked)}
            onProvinceConfirm={setProvinceConfirmed}
            provinceConfirmed={provinceConfirmed} />
        </>
      )}
      <div className='action-step'>
        <a className='btn-step-back _heading cursor-pointer' onClick={onBack}>
          <IconChevronLeft />
          {t('ย้อนกลับ')}
        </a>
        <a onClick={nextDisabled ? () => { } : () => _onNext()} className={cn('btn-step-next btn-main cursor-pointer', { disabled: nextDisabled || minSitesSelected })}>
          {t('ไปหน้าถัดไป')}
          <IconChevronRight />
        </a>
      </div>
    </>
  );
};

const ExamSelectionSummary = ({ day, colorClassName, subjects, recentSubjects = [], examType, pbtPlaces, cbtPlaces, specialRequestMap, provinceConfirmed, onBack }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const pbtCounter = _.countBy(pbtPlaces, 'province');
  const cbtCounter = _.countBy(cbtPlaces, 'province');

  const [place_1, place_2, place_3, place_4, place_5] = pbtPlaces.map(({ site_code }) => site_code);
  const [cbt_place_1, cbt_place_2, cbt_place_3, cbt_place_4, cbt_place_5] = cbtPlaces.map(({ site_code }) => site_code);
  const [submitting, setSubmitting] = useState(false);
  const _onSubmit = async () => {
    try {
      setSubmitting(true);
      await callAPI({
        method: 'POST',
        url: `/applicants/me/exam-selections/${day}`,
        body: {
          subjects: subjects.map(({ subjectKey }) => subjectKey),
          exam_type: examType,

          place_1,
          place_2,
          place_3,
          place_4,
          place_5,

          cbt_place_1,
          cbt_place_2,
          cbt_place_3,
          cbt_place_4,
          cbt_place_5,

          special_requests: specialRequestMap,
          province_confirmed: provinceConfirmed
        },
      });
      setSubmitting(false);
      navigate('/profile/apply-exams/alevel');
    } catch (error) {
      console.error(new Error(`Error while trying to submit: ${error.message}`));
      setSubmitting(false);
    }
  };

  const newSubjects = subjects.filter((subjectKey) => !recentSubjects.includes(subjectKey));
  return (
    <>
      {(newSubjects || []).map((subject, index) => {
        const specialRequestKey = isFlSubject(subject.subjectCode) ? 'Foreign' : subject.subjectKey;
        return (
          <>
            <div className={cn('exam-box', { [colorClassName]: true })}>
              <ul className='exam-box-list'>
                <li key={subject.subjectCode}>
                  <div className='_heading color-blue'>
                    <b>{'วิชาสอบ'}</b>
                  </div>
                  <ul className='list-style-none'>
                    <li>
                      {t('รหัสวิชา')} <b>{subject.subjectCode}</b>
                    </li>
                    <li>
                      {t('ชื่อวิชา')}{' '}
                      <b>
                        {subject.subjectKey} {subject.subjectName}
                      </b>
                    </li>
                    <li>
                      {t('เวลา')} <b>{subject.time}</b>
                    </li>
                    {examType === 'pbt' ? (
                      <li>
                        {t('ประเภทข้อสอบ')} <i className='i-paper' />
                        <b>{t('กระดาษ')}</b>
                      </li>
                    ) : examType === 'cbt' ? (
                      <li>
                        {t('ประเภทข้อสอบ')} <i className='i-computer' />
                        <b>{t('คอมพิวเตอร์')}</b>
                      </li>
                    ) : null}
                  </ul>
                  {Boolean((subject.subSubjects || []).length) && (
                    <>
                      <span className='remark color-blue'>*{t('สำหรับผู้ต้องการสอบวิชาเหล่านี้')}</span>
                      <hr className='hr-dashed' />
                      <ul className='list-style-disc'>
                        {subject.subSubjects.map(({ subjectCode, subjectKey, subjectName }) => (
                          <li key={subjectCode}>
                            {t('รหัสวิชา')} <b>{subjectCode}</b>
                            <br />
                            {t('ชื่อวิชา')}{' '}
                            <b>
                              {subjectKey} {subjectName}
                            </b>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                  {specialRequestMap[specialRequestKey] && (
                    <>
                      <hr className="hr-dashed" />
                      <h4 className="color-orange m-0 flex items-center gap-1">
                        <i className="i-disability"></i>
                        <small><b>{specialRequestMap[specialRequestKey]}</b></small>
                      </h4>
                    </>
                  )}
                </li>
              </ul>
            </div>
            {index < subjects.length - 1 && <div className='_space' />}
          </>
        );
      })}
      <div className={cn('exam-box', { [colorClassName]: true })}>
        <ul className='exam-box-list'>
          <li>
            {examType === 'cbt' && (
              <>
                <div className='_heading color-blue'>
                  <b>{t('สนามสอบคอมพิวเตอร์')}</b>
                </div>
                <ul className='exam-venues'>
                  {(cbtPlaces || []).map((site, index) => {
                    const warning = hasDifferentProvince({ counter: cbtCounter, places: cbtPlaces, site });
                    return (
                      <li key={index} className={cn({ '-error': warning })}>
                        {t('ลำดับที่')} {index + 1}
                        <i className='i-computer' />
                        <div className='col'>
                          <b>
                            {site.school_name} <br />
                            {site.province}
                          </b>
                          {warning && (
                            <>
                              <br />
                              <span>{t('หมายเหตุ : สนามสอบข้ามจังหวัด')}</span>
                            </>
                          )}
                        </div>
                      </li>
                    );
                  })}
                  {Array(5 - cbtPlaces.length)
                    .fill()
                    .map((__, index) => (
                      <li key={index}>
                        {t('ลำดับที่')} {cbtPlaces.length + index + 1}
                        <span>-</span>
                      </li>
                    ))}
                </ul>
              </>
            )}
            <div className='_heading color-blue'>
              <b>
                {t('สนามสอบกระดาษ')}
                {examType === 'cbt' && ` (${t('สำรอง')})`}
              </b>
            </div>
            <ul className='exam-venues'>
              {(pbtPlaces || []).map((site, index) => {
                const warning = hasDifferentProvince({ counter: pbtCounter, places: pbtPlaces, site });
                return (
                  <li key={index} className={cn({ '-error': warning })}>
                    {t('ลำดับที่')} {index + 1}
                    <i className='i-paper' />
                    <div className='col'>
                      <b>
                        {site.school_name} <br />
                        {site.province}
                      </b>
                      {warning && (
                        <>
                          <br />
                          <span>{t('หมายเหตุ : สนามสอบข้ามจังหวัด')}</span>
                        </>
                      )}
                    </div>
                  </li>
                );
              })}
              {Array(5 - pbtPlaces.length)
                .fill()
                .map((__, index) => (
                  <li key={index}>
                    {t('ลำดับที่')} {pbtPlaces.length + index + 1}
                    <span>-</span>
                  </li>
                ))}
            </ul>
            {provinceConfirmed && (
              <>
                <hr className="hr-dashed" />
                <h4 className="color-orange m-0">
                  <b>{t('เลือกสนามสอบใน')} <u>{t('จังหวัด')}{getProvince(pbtPlaces[0].province)}</u> {t('เป็นหลัก')}</b>
                  <small className="block">
                    {t('กรณีสนามสอบที่เลือกไว้ใน')} <u>{t('จังหวัด')}{getProvince(pbtPlaces[0].province)}</u> {t('เต็มและมีการเปิดสนามสอบใหม่ ให้เลือกสนามสอบที่เปิดใหม่นี้ก่อนเลือกสนามสอบในจังหวัดอื่น')}
                  </small>
                </h4>
              </>
            )}
          </li>
        </ul>
      </div>
      <div className='action-step'>
        <a className='btn-step-back _heading cursor-pointer' onClick={onBack}>
          <IconChevronLeft />
          {t('ย้อนกลับ')}
        </a>
        <a className={cn('btn-step-next btn-main cursor-pointer', { loading: submitting })} onClick={submitting ? () => { } : _onSubmit}>
          {t('บันทึกรายการ')}
          <IconChevronRight />
        </a>
      </div>
    </>
  );
};

const ExamFlSubjectConfirmation = ({ day, colorClassName, flSubject, examType, pbtPlaces, specialRequest, onBack, onNext }) => {
  const { t } = useTranslation();
  const pbtCounter = _.countBy(pbtPlaces, 'province');
  return (
    <>
      <div className={cn('exam-box', { [colorClassName]: true })}>
        <ul className='exam-box-list'>
          <li key={flSubject.subjectCode}>
            <div className='_heading color-blue'>
              <b>{'วิชาสอบ'}</b>
            </div>
            <ul className='list-style-none'>
              <li>
                {t('รหัสวิชา')} <b>{flSubject.subjectCode}</b>
              </li>
              <li>
                {t('ชื่อวิชา')}{' '}
                <b>
                  {flSubject.subjectKey} {flSubject.subjectName}
                </b>
              </li>
              <li>
                {t('เวลา')} <b>{flSubject.time}</b>
              </li>
              {examType === 'pbt' ? (
                <li>
                  {t('ประเภทข้อสอบ')} <i className='i-paper' />
                  <b>{t('กระดาษ')}</b>
                </li>
              ) : examType === 'cbt' ? (
                <li>
                  {t('ประเภทข้อสอบ')} <i className='i-computer' />
                  <b>{t('คอมพิวเตอร์')}</b>
                </li>
              ) : null}
            </ul>
            {Boolean((flSubject.subSubjects || []).length) && (
              <>
                <span className='remark color-blue'>*{t('สำหรับผู้ต้องการสอบวิชาเหล่านี้')}</span>
                <hr className='hr-dashed' />
                <ul className='list-style-disc'>
                  {flSubject.subSubjects.map(({ subjectCode, subjectKey, subjectName }) => (
                    <li key={subjectCode}>
                      {t('รหัสวิชา')} <b>{subjectCode}</b>
                      <br />
                      {t('ชื่อวิชา')}{' '}
                      <b>
                        {subjectKey} {subjectName}
                      </b>
                    </li>
                  ))}
                </ul>
              </>
            )}
            {Boolean(specialRequest) && (
              <>
                <hr className="hr-dashed" />
                <h4 className="color-orange m-0 flex items-center gap-1">
                  <i className="i-disability"></i>
                  <small><b>{specialRequest}</b></small>
                </h4>
              </>
            )}
          </li>
        </ul>
      </div>
      <div className='_space' />
      <div className={cn('exam-box', { [colorClassName]: true })}>
        <ul className='exam-box-list'>
          <li>
            <div className='_heading color-blue'>
              <b>
                {t('สนามสอบกระดาษ')}
                {examType === 'cbt' && ` (${t('สำรอง')})`}
              </b>
            </div>
            <ul className='exam-venues'>
              {(pbtPlaces || []).map((site, index) => {
                const warning = hasDifferentProvince({ counter: pbtCounter, places: pbtPlaces, site });
                return (
                  <li key={index} className={cn({ '-error': warning })}>
                    {t('ลำดับที่')} {index + 1}
                    <i className='i-paper' />
                    <div className='col'>
                      <b>
                        {site.school_name} <br />
                        {site.province}
                      </b>
                      {warning && (
                        <>
                          <br />
                          <span>{t('หมายเหตุ : สนามสอบข้ามจังหวัด')}</span>
                        </>
                      )}
                    </div>
                  </li>
                );
              })}
              {Array(5 - pbtPlaces.length)
                .fill()
                .map((__, index) => (
                  <li key={index}>
                    {t('ลำดับที่')} {pbtPlaces.length + index + 1}
                    <span>-</span>
                  </li>
                ))}
            </ul>
            {Boolean(pbtPlaces.length) && (
              <>
                <hr className="hr-dashed" />
                <h4 className="color-orange m-0">
                  <b>{t('เลือกสนามสอบใน')} <u>{t('จังหวัด')}{getProvince(pbtPlaces[0].province)}</u> {t('เป็นหลัก')}</b>
                  <small className="block">
                    {t('กรณีสนามสอบที่เลือกไว้ใน')} <u>{t('จังหวัด')}{getProvince(pbtPlaces[0].province)}</u> {t('เต็มและมีการเปิดสนามสอบใหม่ ให้เลือกสนามสอบที่เปิดใหม่นี้ก่อนเลือกสนามสอบในจังหวัดอื่น')}
                  </small>
                </h4>
              </>
            )}
          </li>
        </ul>
      </div>
    </>
  )
}

const IconChevronLeft = () => (
  <svg width='14' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#a)'>
      <path d='M8.87 1.046a.581.581 0 0 0-.824 0L2.504 6.588a.583.583 0 0 0 0 .824l5.542 5.542a.583.583 0 1 0 .825-.825L3.74 7l5.13-5.13a.583.583 0 0 0 0-.824Z' fill='currentColor' />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='currentColor' transform='rotate(-180 7 7)' d='M0 0h14v14H0z' />
      </clipPath>
    </defs>
  </svg>
);

const IconChevronRight = () => (
  <svg width='14' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M5.13 12.954a.581.581 0 0 0 .824 0l5.542-5.542a.583.583 0 0 0 0-.824L5.954 1.046a.583.583 0 1 0-.825.825L10.26 7l-5.13 5.13a.583.583 0 0 0 0 .824Z' fill='#fff' />
  </svg>
);


const MethodSelector = ({ onClick, onBack, verifyMethod, setVerifyMethod }) => {
  const { t } = useTranslation();
  const profile = useSelector((state) => _.get(state, 'app.user') || {});
  const formattedMobile = [profile.telephone.slice(0, 3), profile.telephone.slice(3, 6), profile.telephone.slice(-4)].join(' ');

  return (
    <>
      <ul className='r-radio'>
        <li>
          <label>
            <input type='radio' name='confirm' checked={verifyMethod === 'mobile'} onClick={() => setVerifyMethod('mobile')} />
            <strong>{t('ยืนยันการแก้ไขวิชาสอบผ่านเบอร์มือถือ')}</strong>
            <small>
              {t('หมายเลข')} {formattedMobile}
            </small>
          </label>
        </li>
        <li>
          <label>
            <input type='radio' name='confirm' checked={verifyMethod === 'email'} onClick={() => setVerifyMethod('email')} />
            <strong>{t('ยืนยันการแก้ไขวิชาสอบผ่านอีเมล')}</strong>
            <small>
              {t('อีเมล')} {profile.email}
            </small>
          </label>
        </li>
      </ul>

      <div className='action'>
        <a className={cn('btn-main', { disabled: !verifyMethod, 'cursor-pointer': Boolean(verifyMethod) })} onClick={!verifyMethod ? () => { } : onClick}>
          {t('ส่ง OTP เพื่อยืนยันการแก้ไขวิชาสอบ')}
        </a>
        <h4>
          <a onClick={onBack} className='cursor-pointer color-gray'>
            {t('ยกเลิก')}
          </a>
        </h4>
      </div>
      <p className='text-center _small color-gray'>
        {t('หากมีการเปลี่ยนแปลงเบอร์มือถือหรืออีเมล')}
        {t('สามารถแก้ไขได้ที่หน้า')}{' '}
        <Link to='/profile/applicant-info' className='color-gray'>
          <u>{t('ข้อมูลการลงทะเบียน')}</u>
        </Link>
      </p>
    </>
  );
};
