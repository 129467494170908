/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { callAPI } from 'helpers';
import moment from 'moment';

// Components
import { Link } from 'react-router-dom';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Data
import { pbt_alevel_recheck, pbt_alevel_dispute } from 'data/enabled';

const SectionApplyExams = () => {
  const { t } = useTranslation();
  const profile = useSelector((state) => _.get(state, 'app.user') || {});
  const [loading, setLoading] = useState(false);
  const [applicationCount, setApplicationCount] = useState(false);
  const [announcable, setAnnouncable] = useState(false);
  const [recheckable, setRecheckable] = useState(false);
  const [disputed, setDisputed] = useState(false);
  const [disputable, setDisputable] = useState(false);

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        setLoading(true);
        const { applications, timestamp, alevel_disputed } = await callAPI({
          url: '/applicants/me/exam-applications',
          query: { type: 'alevel' },
        });
        setApplicationCount(applications.length);
        setAnnouncable(false);
        setRecheckable(
          applications.some((app) => app.recheckable) &&
          moment(timestamp).isBetween(pbt_alevel_recheck.start, pbt_alevel_recheck.end)
        );
        setDisputable(
          moment(timestamp).isBetween(
            pbt_alevel_dispute.start,
            pbt_alevel_dispute.end
          )
        );
        setDisputed(alevel_disputed);
        setLoading(false);
      } catch (error) {
        console.error(`Error while trying to fetch applications: ${error.message}`);
        setLoading(false);
      }
    };
    fetchApplications();
  }, []);

  if (loading) return null;
  return (
    <div className="t-box -exam">
      <h2 className="title">{t('ข้อมูลการสมัครสอบ A-Level')}</h2>
      {!applicationCount ? (
        <div className="text-center">
          <p>{t('ไม่มีข้อมูลการสมัครสอบ A-Level')}</p>
        </div>
      ) : (
        <div className="text-center">
          <p>{t('ข้อมูลการสมัครสอบ A-Level')}</p>
          {!profile.picture ? (
            <p className="color-orange">
              {t('กรุณาอัปโหลดรูปถ่ายผู้สมัคร เพื่อใช้ในการพิมพ์บัตรประจำตัวผู้เข้าสอบรายวิชา')}
              <br />
              {t('สามารถแก้ไขได้ที่หน้า ข้อมูลการลงทะเบียน')}
            </p>
          ) : !profile.program_code ? (
            <p className="color-orange">
              {t('กรุณากรอกข้อมูลหลักสูตรการศึกษา เพื่อใช้ในการพิมพ์บัตรประจำตัวผู้เข้าสอบรายวิชา')}
              <br />
              {t('สามารถแก้ไขได้ที่หน้า ข้อมูลการลงทะเบียน')}
            </p>
          ) : null}
          {profile.picture && profile.program_code ? (
            <div>
              {announcable ? (
                <>
                  <p>
                    <Link
                      to="/profile/exam-scores/alevel"
                      className="btn-main"
                      style={{ width: '100%', maxWidth: '320px' }}
                    >
                      {t('ประกาศผลสอบ A-Level')}
                    </Link>
                  </p>
                  {/* <p className="color-orange">{t('ประกาศผลสอบด้วยกระดาษ วันที่ 7 ม.ค. 2568')}</p> */}
                  {recheckable && (
                    <p>
                      <Link to="/profile/exam-answers/alevel" className="btn-recheck -border">
                        <i className="i-recheck"></i>
                        {t('ดูคำตอบและคะแนนข้อสอบ')}
                      </Link>
                    </p>
                  )}
                  <h4 className="mb-0">
                    <Link to="/profile/exam-application-info/alevel" className="color-gray">
                      {t('รายละเอียดการสอบ')} →
                    </Link>
                  </h4>
                </>
              ) : (
                <>
                  <p>
                    <Link to="/profile/exam-application-info/alevel" className="btn-main btn-noti">
                      {t('รายละเอียดการสอบ')} →
                    </Link>
                  </p>
                  {disputable && (
                    <>
                      <hr />
                      {disputed ? (
                        <div className="color-orange">ผู้สมัครโต้แย้งเฉลยคำตอบเรียบร้อยแล้ว</div>
                      ) : (
                        <>
                          <p className="color-gray">กรณีที่ผู้สมัครต้องการโต้แย้งเฉลยคำตอบ</p>
                          <p>
                            <Link to="/profile/exam-dispute/alevel" className="btn-ghost -large">
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M13 14C11 20 4 14 2 20"
                                  stroke="#FF6600"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M16 16H20.0022C21.1067 16 22.0022 15.1046 22.0022 14V6C22.0022 4.89543 21.1067 4 20.0022 4H10.0013C8.89775 4 8.00274 4.89384 8.00131 5.9974L8 7"
                                  stroke="#FF6600"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M8 14C9.10457 14 10 13.1046 10 12C10 10.8954 9.10457 10 8 10C6.89543 10 6 10.8954 6 12C6 13.1046 6.89543 14 8 14Z"
                                  stroke="#FF6600"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M12 8H18M18 12H15.5"
                                  stroke="#FF6600"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              {t('โต้แย้งเฉลยคำตอบ')}
                            </Link>
                          </p>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default SectionApplyExams;
