import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SectionProfile = () => {
  const { t } = useTranslation();
  return (
    <div className="t-form">
      <h1>การสมัคร</h1>
      <div className="t-box">
        <h2 className="title">
          ข้อมูลการลงทะเบียน
          <a className="_border_0" href="/profile/applicant-info">
            รายละเอียด →
          </a>
        </h2>
        <table>
          <tbody>
            <tr>
              <td className="align-middle" width="140">
                <img alt="Student" src="/assets/img/student_temp.jpg" width="120" className="img-rounded" />
              </td>
              <td className="align-middle _heading">
                <ul className="list-none m-0">
                  <li>
                    ชื่อ <b>ลาลาลา ลาลิซ่า</b>
                  </li>
                  <li>
                    โรงเรียน <b>เตรียมอุดมศึกษา</b>
                  </li>
                  <li>
                    จังหวัด <b>กรุงเทพมหานคร</b>
                  </li>
                  <li>
                    หลักสูตร <b>หลักสูตรแกนกลาง (สามัญ)</b>
                  </li>
                  <li>
                    ปีการศึกษา <b>2566</b>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="t-box -exam">
        <h2 className="title">ข้อมูลการสมัครสอบ TGAT/TPAT2-5</h2>
        <div className="text-center">
          <p>ไม่มีข้อมูลการสมัครสอบ TGAT/TPAT2-5</p>
        </div>
      </div>
      <div className="t-box -exam">
        <h2 className="title">ข้อมูลการสมัครสอบ A-Level</h2>
        <div className="text-center">
          <p>{t('ข้อมูลการสมัครสอบ A-Level')}</p>
          <p className="mb-6">
            <Link to="#" className="btn-main btn-noti">
              {t('รายละเอียดการสอบ')} →
            </Link>
          </p>
          <hr />
          <p className="color-gray">กรณีที่ผู้สมัครต้องการโต้แย้งเฉลยคำตอบ</p>
          <p>
            <Link to="/_temp/exam-vetos/consent" className="btn-ghost -large">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13 14C11 20 4 14 2 20"
                  stroke="#FF6600"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16 16H20.0022C21.1067 16 22.0022 15.1046 22.0022 14V6C22.0022 4.89543 21.1067 4 20.0022 4H10.0013C8.89775 4 8.00274 4.89384 8.00131 5.9974L8 7"
                  stroke="#FF6600"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8 14C9.10457 14 10 13.1046 10 12C10 10.8954 9.10457 10 8 10C6.89543 10 6 10.8954 6 12C6 13.1046 6.89543 14 8 14Z"
                  stroke="#FF6600"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12 8H18M18 12H15.5"
                  stroke="#FF6600"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              {t('โต้แย้งเฉลยคำตอบ')}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SectionProfile;
